import React, {useState, useEffect} from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {FaBars, FaDiscord, FaTwitter} from 'react-icons/fa';
import {IconContext} from 'react-icons/lib';
import {
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon,
    NavMenu, 
    NavItem, 
    NavLinks,
    NavBtn,
    NavBtnLink,
    NavLogoImg,
    LogoContainer
} from './NavbarElements';
import logo from '../../images/logo.png'
import { HeroH1 } from '../HeroSection/HeroElements';
import { SocialIconLink } from '../Footer/FooterElements';


const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);
    const [ hover, setHover ] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <LogoContainer>
                        <NavLogo to='/' onClick={toggleHome}>
                            {/* <NavLogoImg src={logo} /> */}
                            <HeroH1  style={{"-webkit-text-stroke":"2px black","font-family": "cartoon","font-weight": "100"}}>Wizards x Knights</HeroH1>
                        </NavLogo>
                        <SocialIconLink href='https://twitter.com/FantomKnights' target='_blank' aria-label='Twitter'><FaTwitter/></SocialIconLink><SocialIconLink href='https://discord.gg/FantomKnights' target='_blank' aria-label='Discord'><FaDiscord /></SocialIconLink>
                    </LogoContainer>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                  
                        <NavItem>
                            <NavLinks to='home' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                Mint
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to='view' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                View
                            </NavLinks>
                        </NavItem> 
                        <NavItem >
                            <NavLinks isWar={true} to='war' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                 WAR
                            </NavLinks>
                        </NavItem> 
                        <NavItem>
                            <NavLinks to='charity' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                Charity
                            </NavLinks>
                        </NavItem> 
                           
                        <NavItem>
                            <NavLinks to='roadmap' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                                Roadmap
                            </NavLinks>
                        </NavItem>
                       
                       
                    </NavMenu>
                    
                </NavbarContainer>
            </Nav>            
        </IconContext.Provider>
        </>
    );
};

export default Navbar;
