const initialState = {
    loading: false,
    account: null,
    smartContract: null,
    web3: null,
    errorMsg: "",
    smartContractWizards:null,
    smartContractMounts:null,
  };


// Blockchain reducer, interacts with blockchain data by fetching and updating information correctly
// "Reducers" are triggered by "Actions"
const blockchainReducer = (state = initialState, action) => {
   switch (action.type) {
    // 1. Returns intial state and loading=true since its connecting is "loading"
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    // 2. When Connection is succesfully made --> update feilds accordingly
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        web3: action.payload.web3,
        smartContractWizards: action.payload.smartContractWizards,
        smartContractMounts: action.payload.smartContractMounts
      };
    // 3. If Connection is failed --> Don't update any feilds but error and loading to false
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    // 4. Update account, if account switched update account to what was passed to us
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }

}

export default blockchainReducer;
