import React, { useState, useRef, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import Modal from '@mui/material/Modal';
import MaterialButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'

import './CurrentGuild.css'
import TabContext from '@mui/lab/TabContext';
import { ContactSupportTwoTone } from '@mui/icons-material';



function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



const rows = [
    createData('Frozen yoghurt', numberWithCommas(1059000), 6.0, 24, 4.0),
    createData('Ice cream sandwich', 6666666, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const style = {
    position: 'relative',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
    // width: '50%',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid',
    boxShadow: 24,
    p: 4,
    // width:"40%"


};


function removeWalletFromAddress() {
}

const CreateGuild = ({ myTeam, handleKick }) => {
    const [myTeamObj, setMyTeamObj] = useState(myTeam);
    const [allMembers, setAllMembers] = useState([]);
    const [valueModal, setValueModal] = useState('1');
    
    const[walletAddressToKick, setWalletAddressToKick] = useState("null")
    //modals
    const [open, setOpen] = useState(false);

    function handleOpen(walletAddress) {
        // console.log("handleopen" );
        // console.log(walletAddress)
        setWalletAddressToKick(walletAddress)
        setOpen(true);
    }

    function handleClose() {
        // console.log("handleClose");
        setOpen(false);
    }

    const handleChange = (event, newValueModal) => {
        setValueModal(newValueModal);
    };
    function numberWithCommas(x) {
        // console.log("x = " + x)
        if(!x)return ""
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }


    function handleKickResponse(response, member) {
        if (response) {
            handleKick(member);
        }
        setOpen(false);
    }
    function shortenWallet(walletAddress){
        return walletAddress.substring(0,4) + "..." + walletAddress.substring(walletAddress.length-4)
    }

    useEffect(() => {
        var allMembers = [];
        allMembers.push({ walletAddress: myTeam.ownerWalletAddress, powerLevel:myTeam.ownerPowerLevel,numKnights:myTeam.ownerNumKnights, nickName: myTeam.ownerNickName });
        myTeam.members.filter(mem => mem.status == "Joined").map(mem => {
            allMembers.push(mem);
        });
        setAllMembers(allMembers);

    }, [myTeam]);
    return (
        <>
            <TableContainer component={Paper} style={{ maxHeight: window.innerWidth > 800 ? 400 : 300 }} >
                <Table aria-label="simple table" stickHeader style={{ textAlign: "center" }} stickyHeader  >
                    <TableHead>
                        <TableRow >
                            <TableCell style={{ textAlign: "center", backgroundColor: "#16181e", fontFamily: "cartoon", color: "white" }}>Wallet Address</TableCell>
                            <TableCell align="center" style={{ backgroundColor: "#16181e", fontFamily: "cartoon", color: "white" }}>Power Level</TableCell>
                            <TableCell align="center" style={{ backgroundColor: "#16181e", fontFamily: "cartoon", color: "white" }}>Army Size</TableCell>
                            <TableCell align="center" style={{ backgroundColor: "#16181e", fontFamily: "cartoon", color: "white" }}></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody stripedRows >
                        {allMembers.map((mem) => (
                            <>
                                <TableRow
                                    key={mem.walletAddress}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" align="center" style={{ fontFamily: "cartoon" }}


                                    >
                                        {mem.nickName?' ['+mem.nickName+'] ':''}{mem.walletAddress}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontFamily: "cartoon" }}>{numberWithCommas(mem.powerLevel)}</TableCell>
                                    <TableCell align="center" style={{ fontFamily: "cartoon" }}>{mem.numKnights}</TableCell>
                                    {myTeam.isOwner  && <TableCell align="center" style={{ fontFamily: "cartoon" }}>{ mem.walletAddress!=myTeam.ownerWalletAddress && <PersonRemoveIcon style={{cursor:"pointer"}} onClick={() => handleOpen(mem.walletAddress)}>

                                    </PersonRemoveIcon>}</TableCell>}
                                </TableRow>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"

                                >

                                    <Box sx={style} className="modalSizeAcceptDecline" >
                                        <p style={{ textAlign: "center" }}>MANAGE USER: {shortenWallet(walletAddressToKick)}</p>
                                        <div style={{ justifyContent: "center", display: "flex" }}>

                                            <Button onClick={() => handleKickResponse(true,{walletAddress:walletAddressToKick})} variant="outlined" color="success" style={{ marginRight: "10px" }}>
                                                KICK USER
                                            </Button>
                                            <Button onClick={() => handleKickResponse(false, {walletAddress:walletAddressToKick})} variant="outlined" color="error" >
                                                DONT KICK
                                            </Button>
                                        </div>
                                    </Box>
                                </Modal>

                            </>

                        ))}
                    </TableBody>
                </Table>

            </TableContainer>


        </>

    );
}

export default CreateGuild;