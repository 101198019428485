import React from 'react';
import {animateScroll as scroll} from 'react-scroll';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin,FaDiscord } from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap, 
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    
    SocialMedia,
    SocialMediaWrap,
    SocialLogo, 
    WebsiteRights,
    SocialIcons,
    SocialIconLink
} from './FooterElements';

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <FooterContainer>
            <FooterWrap>
                
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome} >
                            Wizards x Knights
                        </SocialLogo>
                        <WebsiteRights>Fantom Realm &copy; {new Date().getFullYear()} All Rights Reserved</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href='https://twitter.com/FantomKnights' target='_blank' aria-label='Twitter'>
                                <FaTwitter />
                            </SocialIconLink>
                
                            <SocialIconLink href='https://discord.gg/FantomKnights' target='_blank' aria-label='Discord'>
                                <FaDiscord />
                            </SocialIconLink>
                           
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
