
import styled from 'styled-components';

export const RoadH1 = styled.h1`
color: #fff;
font-size: 38px;
text-align: center;
padding-left: 10px;
padding: 30px;
@media screen and (max-width: 768px) {
    font-size: 40px;
}

@media screen and (max-width: 480px) {
    font-size: 32px;
}
`
export const RoadMapContainer = styled.div`
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};

    @media screen and (max-width: 768px) {
        padding: 100px 0;

    }
`
let timelineElements = [
    {
      id: 1,
      title: "Phase 1: Fantom Knight Launch",
      location: "",
      description:
        "We reveal the Fantom Knights to the Fantom community and give them a sneak peek of our aesthetically pleasing collection.",
      icon: "completed",
    },
    {
      id: 2,
      title: "Phase 2: Fantom Knight Giveaways and Collaborations",
      description:
        "We will be partnering with other projects in the Fantom network to provide the Fantom community with a bunch of free NFTs and $FTM. We will also be holding our own community driven events to give back to our members.",
      icon: "ongoing",
    },
    {
      id: 3,
      title: "Phase 3: Website Launch",
      description:
        "Launch the Fantom Knight website.",
      icon: "completed",
    },
    {
      id: 4,
      title: "Phase 4: Release details about the Fantom Knights WAR",
      description:
        "Holders will be able to participate in a WAR to earn FTM. More details coming soon!",
      icon: "completed",
    },
    {
      id: 5,
      title: "Phase 5: Mint Day",
      description:
        "Minting open to public allowing members to recruit their very own Fantom Knights!",
      icon: "completed",
    },
    {
      id: 6,
      title: "Phase 6: Community Growth",
      description:
        "$FTM donations will be made to the community chosen charity via TheGivingBlock. The Fantom Knights Holders will be able to earn FREE FTM via scheduled WARs. Continuous collaboration and partnerships within the Fantom community and beyond.",
      buttonText:"www.TheGivingBlock.com",
      icon: "ongoing",
    },
    {
      id: 7,
      title: "Phase 7: The WAR Release",
      description:
        "The Fantom Knight WAR will begin after all the knights have been minted. Only holders will be eligible to enter the WAR to compete against other holders for the FTM$ prize pool!",
      icon: "completed",
    },
    {
      id: 8,
      title: " Phase 8: Fantom Wizards & Mounts",
      description:
        "1111 Wizards and 1 Queen limited Collection! Each Wizard and Knight holder will be rewarded with a mount! \n Mounts will provide INSANE boosts to power levels.\n All can be used in our ongoing weekly WARs.",

      icon: "ongoing",
    },
  ];
  
  export default timelineElements;
