import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Search } from '../ButtonElement';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Header,
  ImageWrappers,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  PowerLevelBar,
  TokenNumber,
  Attribute,
  AttributeList,
  Container,
  ShowCollection,
  ShowCollectionWizard
}
  from './CollectionElements';
import { getMultipleTokens, getMultipleTokensWizard, getMultipleTokensMount } from '../../api/index';


const CollectionSection = ({ alt, imageURL, isKnight }) => {
  const [powerLevel, setPowerLevel] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [token, setToken] = useState(0);
  const [defaultState, setDefaultState] = useState(true);
  const [powerMap, setPowerMap] = useState({});
  const [powerMapWizard, setPowerMapWizard] = useState({});
  const [powerMapMount, setPowerMapMount] = useState({});

  const data = useSelector((state) => state.data);
  const [showCollection, setShowCollection] = useState(false);
  const [showMounts, setShowMounts] = useState(false);

  const powerLevelMap = {};
  const { REACT_APP_FANTOM_KNIGHTS_BACKEND_URL } = process.env;



  function toggleViewCollection() {

    // Turn off Mounts AND allow toggling of Knights/Wizards
    setShowMounts(false)
    setShowCollection(!showCollection)
  }


  function toggleViewMounts() {
    // turn off Knights/Wizards AND allow toggling of Mounts
    setShowCollection(false)
    setShowMounts(!showMounts)
  }

  function getAllTokens(idsToFetch) {
    getMultipleTokens(idsToFetch)
      .then(res => {

        if (res.status == 200) {
          let powerMapTemp = {};

          for (var curr of res.data.results) {
            powerMapTemp[parseInt(curr.id)] = parseInt(curr.powerLevel);
          }
          const newpower = JSON.stringify(powerMapTemp)
          setPowerMap(JSON.parse(newpower))
        } else {
          throw new Error('Something went wrong');
        }
      });
  }


  function getAllTokensWizards(idsToFetch) {
    getMultipleTokensWizard(idsToFetch)
      .then(res => {

        if (res.status == 200) {
          let powerMapTemp = {};
          for (var curr of res.data.results) {
            powerMapTemp[parseInt(curr.id)] = parseInt(curr.powerLevel);
          }
          const newpower = JSON.stringify(powerMapTemp)
          setPowerMapWizard(JSON.parse(newpower))
        } else {
          throw new Error('Something went wrong');
        }
      });
  }


  function getAllTokensMounts(idsToFetch) {

    //walletOfOwnerMounts
    getMultipleTokensMount(idsToFetch)
      .then(res => {

        if (res.status == 200) {
          let powerMapTemp = {};

          // console.log(res.data.results)

          for (var curr of res.data.results) {
            powerMapTemp[parseInt(curr.id)] = parseInt(curr.powerLevel);
          }
          const newpower = JSON.stringify(powerMapTemp)
          setPowerMapMount(JSON.parse(newpower))

        } else {
          throw new Error('Something went wrong');
        }
      });
  }


  useEffect(() => {
    if (data.account) {
      // passing in Knights here
      getAllTokens(data.walletOfOwner);

      //new call for wizards here (data.walletOfOwnerWizard)
      getAllTokensWizards(data.walletOfOwnerWizard);


      // New call to get all Mounts here for owner
      getAllTokensMounts(data.walletWithMount)

    }

  }, [data.walletOfOwner]);



  return (
    <>
      <InfoContainer id={'collection'} lightBg={true} >

        <InfoWrapper>
          <Header>
            {isKnight ?
              <><ShowCollection onClick={(e) => toggleViewCollection()}>
                View Collection
              </ShowCollection>
                <ShowCollection onClick={(e) => toggleViewMounts()}>
                  View Mounts
                </ShowCollection></>

              :
              <><ShowCollectionWizard onClick={(e) => toggleViewCollection()}>
                View Collection
              </ShowCollectionWizard>

                <ShowCollection onClick={(e) => toggleViewMounts()}>
                  View Mounts
                </ShowCollection></>
            }
          </Header>
          {showCollection && <ImageWrappers>
            {isKnight ?
              <>
                {data.walletOfOwner.map(tokenId =>
                  <Container>
                    <TokenNumber>Fantom Knight {tokenId}</TokenNumber>
                    <ImgWrap fillerNeeded={false}>
                      {/* <Img src={'https://fantomknights.art/images/'+tokenId} alt={alt}/>  */}

                      <Img src={imageURL + tokenId} alt={alt} />
                    </ImgWrap>
                    <PowerLevelBar>Power Level: {powerMap[tokenId]}
                    </PowerLevelBar>

                  </Container>


                )}

                {/* data.walletOfOwnerWizard instead */}
                {data.walletOfOwner.length == 0 && <div> {data.account ? 'You currently have no knights!' : 'Connect your wallet first!'}</div>}
              </>
              :
              <>
                {data.walletOfOwnerWizard.map(tokenId =>
                  <Container>
                    <TokenNumber>Fantom Wizard {tokenId}</TokenNumber>
                    <ImgWrap fillerNeeded={false}>
                      {/* <Img src={'https://fantomknights.art/images/'+tokenId} alt={alt}/>  */}

                      <Img src={imageURL + tokenId} alt={alt} />
                    </ImgWrap>
                    <PowerLevelBar>Power Level: {powerMapWizard[tokenId]}
                    </PowerLevelBar>

                  </Container>


                )}


                {data.walletOfOwnerWizard.length == 0 && <div> {data.account ? 'You currently have no wizards!' : 'Connect your wallet first!'}</div>}
              </>
            }

          </ImageWrappers>}

          {showMounts && <ImageWrappers>

            {data.walletWithMount.map(tokenId =>
              <Container>
                <TokenNumber>Fantom Mount {tokenId}</TokenNumber>
                <ImgWrap fillerNeeded={false}>
                  {/* <Img src={'https://fantomknights.art/images/'+tokenId} alt={alt}/>  */}

                  <Img src={REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+ "/mounts/images/" + tokenId} alt={alt} />
                  {/* <Img src={"http://localhost:5000/mounts/images/" + tokenId} alt={alt} /> */}
                </ImgWrap>
                <PowerLevelBar>Power Level: {powerMapMount[tokenId]}
                </PowerLevelBar>

              </Container>


            )}

          </ImageWrappers>}

          {


          }
        </InfoWrapper>
      </InfoContainer>

    </>
  )
}

export default CollectionSection;
