import React from 'react';

import { ReactComponent as CheckIcon } from "../../images/check.svg";
import { ReactComponent as PendingIcon } from "../../images/hourglass.svg";
import { ReactComponent as RepeatIcon } from "../../images/repeat.svg";

import timelineElements from './RoadMapElements';
import { RoadH1,RoadMapContainer } from './RoadMapElements';
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  
import "react-vertical-timeline-component/style.min.css";

const RoadMapSection = ({lightBg, id, imgStart, topLine, lightText, headline, description, darkText, buttonLabel, alt, img, dark, primary} ) => {
    
    let completedIconStyles = { background: "#06D6A0" };
    let pendingIconStyles = { background: "#f9c74f" };
    return (
      <RoadMapContainer id={"roadmap"} lightBg={lightBg}> 
        <div style={{"background":"black","font-weight":"0"}}>
        <RoadH1>Road Map</RoadH1>
        <VerticalTimeline >
          {timelineElements.map((element) => {
            let isCompletedIcon = element.icon === "completed";
            let isOngoingIcon = element.icon === "ongoing";
            let showButton =
              element.buttonText !== undefined &&
              element.buttonText !== null &&
              element.buttonText !== "";
  
            return (
              <VerticalTimelineElement
                key={element.key}
                date={element.date}
                dateClassName="date"
                iconStyle={isCompletedIcon || isOngoingIcon  ? completedIconStyles : pendingIconStyles}
                icon = {
                  (() => {
                    if (isCompletedIcon)
                       return  <CheckIcon />
                    if (isOngoingIcon)
                       return <RepeatIcon/> 
                    else
                       return <PendingIcon />
                })()
                }


              >
                <h3 className="vertical-timeline-element-title">
                  {element.title}
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  {element.location}
                </h5>
                <p id="description">{element.description}</p>
                {showButton && (
                  <a
                    className={`button ${
                      isCompletedIcon ? "workButton" : "schoolButton"
                    }`}
                    href="https://thegivingblock.com/"
                    target='_blank'
                  >
                    TheGivingBlock
                  </a>
                )}
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
        
      </div>
      </RoadMapContainer>
    )
}

export default RoadMapSection;
