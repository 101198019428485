// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import SmartContract from "../../contracts/FantomKnightABI.json";
import SmartContractWizards from "../../contracts/FantomWizardsABI.json";
import SmartContractMounts from "../../contracts/FantomMountsABI.json";

// import SmartContractKnights from "../../contracts/FantomKnightABI.json";

import { fetchData } from "../data/dataActions";

// "Actions" are functions that trigger the reducers

// 1. Intial account request setup, put it in loading screen ...
const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

// 2. When connection has been made succesfully, pass the correct data and update state accordingly
const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  }
};

// 3.  When connection has been failed, adjust data accordingly
const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

// 4. On account change, update state accordingly (the account feild)
const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};



// Connect to metamask wallet
export const connect = () => {
  const {REACT_APP_FANTOM_NETWORK_ID,REACT_APP_FANTOM_KNIGHTS_CONTRACT, REACT_APP_FANTOM_WIZARDS_CONTRACT, REACT_APP_FANTOM_MOUNTS_CONTRACT} = process.env;
  return async (dispatch) => {
    //1. begin my initiaing connect state
    dispatch(connectRequest());
    // extract etherium object from web3
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    // 2. Make sure metamask is installed
    if (metamaskIsInstalled) {
        // console.log("metamask is installed")
      Web3EthContract.setProvider(ethereum);
      //initiating web3 which has all etherium related modules
      let web3 = new Web3(ethereum);
      try {
        // connect to metamask account (popup)
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        // console.log("accounts = " + accounts)
        // console.log("REACT_APP_FANTOM_NETWORK_ID = " + REACT_APP_FANTOM_NETWORK_ID)
        // console.log("REACT_APP_FANTOM_KNIGHTS_CONTRACT = " + REACT_APP_FANTOM_KNIGHTS_CONTRACT)

        //extract the network metamask account is one  (5 is etherium testnet)
        const networkId = await ethereum.request({
          method: "net_version",
        });
        // console.log("networkId = " + networkId)

        // const NetworkData = await SmartContract.networks[networkId];
        // check if network id matches the one we want
        // if (networkId == 4002) {
        if (networkId == REACT_APP_FANTOM_NETWORK_ID) {

            // Allows us to interact with smart contract (creates a javascript object using the ABI under contracts)
            const SmartContractObj = new web3.eth.Contract(
                SmartContract,
                REACT_APP_FANTOM_KNIGHTS_CONTRACT
                
            );

            // console.log(REACT_APP_FANTOM_KNIGHTS_CONTRACT)



            // NEW: FANTOM Wizards
            // Allows us to interact with smart contract (creates a javascript object using the ABI under contracts)
            const SmartContractObjWizards = new web3.eth.Contract(
              SmartContractWizards,
              REACT_APP_FANTOM_WIZARDS_CONTRACT
              
            );


            const SmartContractObjMounts = new web3.eth.Contract(
              SmartContractMounts,
              REACT_APP_FANTOM_MOUNTS_CONTRACT
              
            );
          
          // console.log(REACT_APP_FANTOM_MOUNTS_CONTRACT)
          // console.log(SmartContractObjMounts)
          
            // console.log(REACT_APP_FANTOM_WIZARDS_CONTRACT)
              // console.log(SmartContractObj)
            //if everythigns succesfull --> update state accordingly
            dispatch(
                connectSuccess({
                account: accounts[0],
                smartContract: SmartContractObj,
                web3: web3,
                smartContractWizards:SmartContractObjWizards,
                smartContractMounts:SmartContractObjMounts

                })
            );

            // console.log("AFTER connectSuccess" )

            // Add listeners start (if account gets changed on metamask while on site, trigger updateAccount)
            ethereum.on("accountsChanged", (accounts) => {
                dispatch(updateAccount(accounts[0]));
            });
            ethereum.on("chainChanged", () => {
                window.location.reload();
            });
            // Add listeners end
        } else { // if we are on the wrong network
            // console.log("Change network to Fantom")
            dispatch(connectFailed("Change to Fantom Opera"));
        }
      } catch (err) {
        // console.log("Something went wrong.")
        dispatch(connectFailed("Something went wrong."));
      }
    } else { // if metamask not installed
        // console.log("Install Metamask.")
      dispatch(connectFailed("Install Metamask."));
    }
  };
};


// updates account based on information changed + contract details from smartcontract object extracted created via web3.eth.contract above
export const updateAccount = (account) => {
    return async (dispatch) => {
      dispatch(updateAccountRequest({ account: account }));
      dispatch(fetchData(account));
    };
  };