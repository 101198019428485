import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  // console.log("fetchDataSuccess == ")
  // console.log(payload.walletOfOwnerMount)
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

// const NAME = "FantomKnights"
// const COST = 66000000000000000000
// const MAXSUPPLY = 2001

// changeback TO THIS
const NAME = "FantomWizards"
// const COST = 99000000000000000000
const COST = 66000000000000000000
const MAXSUPPLY = 1111

//changeback
// const NAME = "fwtest"
// const COST = 100000000000000000
// const MAXSUPPLY = 1111


//fetch data
export const fetchData = (account) => {
  // console.log("fetchData CALL : ENTERED")
  return async (dispatch) => {
    //intialize state for data
    dispatch(fetchDataRequest());
    try {
      // fetch all the details about the contract from blockchainReducer (via store)
      // Oct 31 -- 230 pm (commented out)
      // let name = await store
      //   .getState()
      //   .blockchain.smartContractWizards.methods.name()
      //   .call();

      let name = NAME

      // console.log("name === " + name)
// smartContractWizards
      let totalSupply = await store
        .getState()
        .blockchain.smartContractWizards.methods.totalSupply()
        .call();


      // console.log("totalSupply === " + totalSupply)

      // Oct 31 -- 230 pm (commented out)
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContractWizards.methods.cost()
      //   .call();

      let cost = COST
      // console.log("cost === " + cost)

      // Oct 31 -- 230 pm (commented out)
      // let maxSupply = await store
      //   .getState()
      //   .blockchain.smartContractWizards.methods.maxSupply()
      //   .call();
      let maxSupply = MAXSUPPLY;
      // console.log("maxSupply ===" + maxSupply)


      let account = await store
        .getState()
        .blockchain.account;

      // console.log(account);
      // account ="0x1c6c476117fc7cd39ee7b9fc6e76946d2cf39bc7";
      // account="0x3e590fbb993bf1e97f04fe615c64bfa37dd84999";
        // account="0x1de82f878e6aa9c82a93f1956893281581a8fbc0";
      // Wizard wallet owner
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(account + "")
        .call();

      // console.log("Knight WALLET === " + walletOfOwner)

      // Knight wallet owner
      let walletOfOwnerWizard = await store
      .getState()
      .blockchain.smartContractWizards.methods.walletOfOwner(account + "")
      .call();


      // console.log("Wizard WALLET === " + walletOfOwnerWizard)
      // console.log(walletOfOwner);

      let walletOfOwnerMount = await store
      .getState()
      .blockchain.smartContractMounts.methods.walletOfOwner(account + "")
      .call();
      
      // console.log("wallet mounts!!! HERE IBAD!")
      // console.log(walletOfOwnerMount)

      // update the state after extracting data from blockchainReducer (via store)
      dispatch(
        fetchDataSuccess({
          name,
          totalSupply,
          cost,
          maxSupply,
          walletOfOwner,
          account,
          walletOfOwnerWizard,
          walletOfOwnerMount
          
        })
      );
    } catch (err) {
      // console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

