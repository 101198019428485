
export const homeObjTwo = {
    id: 'view',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'View',
    headline: 'Search for a knight',
    description: ' (1-2001)',
    buttonLabel: 'Search',
    imgStart: true,
    img: require('../../images/question.png').default,
    imgW: require('../../images/questionW.png').default,
    alt: 'Mystery',
    dark: false,
    primary: false,
    darkText: true,
};



export const charityObj = {
    id: 'charity',

};

