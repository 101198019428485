import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "@mui/material/Modal";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { Button, Image } from "semantic-ui-react";
import { IconButton } from "@mui/material";

import PendingMembers from "./PendingMembers";
import BasicTable from "./CurrentGuild";

//  Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import useWindowDimensions from "../../useWindowDimensions";

import "./MyGuild.css";

import CurrentTeam from "../MyGuild/CurrentGuild";

import CreateGuild from "../MyGuild/CreateGuild";

import { WarButton, NavBar } from "./TeamElements";
import _ from "lodash";
import {
  acceptGuildMember,
  changeNickNameChat,
  createGuild,
  declineGuildMember,
  deleteGuild,
  getMyCurrentGuild,
  getTeams,
  kickGuildMember,
  leaveGuild,
  updateGuildCall,
} from "../../../api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import GuildChat from "../Chat";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";

// let Filter = require('bad-words'),
import Filter from "bad-words";

import questionCharacter from "../../../images/question.png";
import GuildEdit from "./GuildEdit";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const style = {
  position: "relative",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  // width: '50%',
  // height: '50%',
  bgcolor: "background.paper",
  border: "2px solid",
  boxShadow: 24,
  p: 4,
  // width:"40%"
};

const styleChangeNickName = {
  position: "relative",
  top: "50%",
  left: "50%",
  maxHeight: '90vh',
  overflow: 'auto',
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid",
  boxShadow: 24,
  p: 4,
  maxWidth: window.innerWidth > 540 ? 450 : 290,
  minWidth: window.innerWidth > 540 ? 450 : 290,
  fontFamily: "cartoon",
};
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const MyGuild = () => {
  const [myTeam, setMyTeam] = useState({});
  const [renderChange, setRenderChange] = useState(0);
  const [myTeamStatus, setMyTeamStatus] = useState(
    "You are not part of a Guild"
  );
  const [sectionToLoad, setSectionToLoad] = useState("Roster");

  const Item = styled(Paper)(({ theme }) => ({
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  }));

  const data = useSelector((state) => state.data);

  const { REACT_APP_FANTOM_KNIGHTS_BACKEND_URL } = process.env;

  const numbers = [1, 2, 3];

  const [open, setOpen] = useState(false);
  const [nameOpen, setNameOpen] = useState(false);
  const [nickName, setNickName] = useState("");
  const [savedNickName, setSavedNickName] = useState("");
  const [chatPicNum, setChatPicNum] = useState("");
  const [selectedPicNum, setSelectedPicNum] = useState("");
  const { height, width } = useWindowDimensions();

  const [createGuildToggle, setCreateGuildToggle] = useState(false);

  function getTotalPowerLevel() {
    var totalPwerLevel = 0;
    totalPwerLevel += myTeam.ownerPowerLevel;
    myTeam.members
      .filter((mem) => mem.status == "Joined")
      .map((mem) => {
        totalPwerLevel += mem.powerLevel;
      });
    return totalPwerLevel.toFixed(0);
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function getMemberCount(){
    let count =0;
    myTeam.members.map(mem=>{if(mem.status=="Joined")count+=1});
    return count+1;
  }

  //TODO: Change to return only related team for address, need api created for it
  async function getMyTeam() {
    try {
      let response = await getMyCurrentGuild(data.account);
      if (response.status == 201) {
        let team = response.data;
        let memInfo = team.members.find((mem) => {
          return (
            mem.walletAddress.toString().toLowerCase() ===
            data.account.toString().toLowerCase()
          );
        });
        if (memInfo !== undefined) {
          if (memInfo.status == "Joined") {
            team.isOwner = false;
            setNickName(memInfo.nickName);
            setSavedNickName(memInfo.nickName);
            setMyTeam(team);
            setChatPicNum(memInfo.chatPicNum);
            setSelectedPicNum(memInfo.chatPicNum);
          } else {
            setMyTeamStatus(
              `You have already applied to the guild "${team.name}" but have not been accepted!`
            );
          }
        } else if (
          team.ownerWalletAddress.toString().toLowerCase() ===
          data.account.toString().toLowerCase()
        ) {
          team.isOwner = true;
          setChatPicNum(team.ownerChatPicNum);
          setNickName(team.ownerNickName);
          setSavedNickName(team.ownerNickName);
          setSelectedPicNum(team.ownerChatPicNum);

          setMyTeam(team);
        }
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      setMyTeam({});

      // console.log(error);
    }
  }

  useEffect(() => {
    getMyTeam();
    // console.log(renderChange);
  }, [renderChange]);

  function toggleCreateGuildToggle() {
    setCreateGuildToggle(!createGuildToggle);
  }
  function leaveTeamFunc() {
    if (myTeam.isOwner) {
      deleteGuild(myTeam.name, data.account).then((res) => {
        getMyTeam().then(() => {
          setRenderChange(renderChange + 1);
          setOpen(false);
        });
      });
    } else {
      leaveGuild(myTeam.name, data.account, data.walletOfOwner).then((res) => {
        getMyTeam().then(() => {
          setRenderChange(renderChange + 1);
          setOpen(false);
        });
      });
    }
  }
  function handleResponse(response, pendingMember) {
    if (response) {
      acceptGuildMember(
        pendingMember.walletAddress,
        myTeam.name,
        data.account
      ).then((res) => {
        getMyTeam().then(() => {
          setRenderChange(renderChange + 1);
        });
      });
    } else {
      declineGuildMember(
        pendingMember.walletAddress,
        myTeam.name,
        data.account
      ).then((res) => {
        getMyTeam().then(() => {
          setRenderChange(renderChange + 1);
        });
      });
    }
  }
  function handleKick(member) {
    kickGuildMember(member.walletAddress, myTeam.name, data.account).then(
      (res) => {
        getMyTeam().then(() => {
          setRenderChange(renderChange + 1);
        });
      }
    );
  }
  function createNewGuild(newTeamObj) {
    // console.log("sheheryar")
    // console.log(newTeamObj)
    createGuild(
      newTeamObj.guildName,
      newTeamObj.guildDesc,
      data.account,
      newTeamObj.imageURL
    )
      .then((res) => {
        // console.log(res.status)

        if (res.status == 201) {
          setCreateGuildToggle(false);
          getMyTeam().then(toast.success("Guild succesfully created!"));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message + "!");
      });
  }
  async function guildUpdate(type, newVal) {
    return await updateGuildCall(myTeam.name, type, newVal, data.account)
      .then((res) => {
        // console.log(res.status)

        if (res.status == 201) {
          getMyTeam().then(toast.success("Guild succesfully updated!"));
          return true;
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message + "!");
        getMyTeam();
        return false;
      });
  }
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  function handleNameClose() {
    setNameOpen(false);
  }
  function handleNameOpen() {
    setNickName(savedNickName);
    setNameOpen(true);
  }

  function handleNameChange(e) {
    setNickName(e.target.value);
  }
  function sanitizeInput(inputString) {
    if (/^[\w\-\s]+$/.test(inputString)) {
      return true;
    } else {
      return false;
    }
  }

  function changeName() {
    let filter = new Filter();

    if (nickName.length > 0 && nickName.length <= 10) {
      let cleanNickName = filter.clean(nickName);
      if (sanitizeInput(cleanNickName)) {
        changeNickNameChat(
          myTeam.name,
          data.account,
          nickName,
          selectedPicNum ? selectedPicNum : ""
        ).then((res) => {
          getMyTeam().then(() => {
            toast.success("Nickname changed!");

            setRenderChange(renderChange + 1);
          });
        });
        setNameOpen(false);
      } else {
        toast.error(
          "Nickname must be Alphabets & Numbers only with no profanity!"
        );
      }
    } else {
      toast.error("Nickname should be between 1-10 Characters");
    }
  }
  return (
    // <>
    //     {numbers.map(num => (
    //         <TeamCard></TeamCard>
    //     ))}
    // </>

    // <div> hello</div>
    <>
      {/* <h4 class="loading">Pending Members </h4> */}
      {/* <Grid container spacing={2} maxHeight={600} style={{justifyContent:"center", marginTop:"0px"}}> */}

      {/* <Grid item xs={"auto"}> */}
      {/* <BasicTable></BasicTable> */}
      {/* </Grid> */}

      {/* </Grid> */}

      <Grid
        container
        spacing={2}
        maxHeight={window.innerWidth > 800 ? 600 : 370}
        style={{ justifyContent: "center", marginTop: "0px" }}
      >
        {_.isEmpty(myTeam) && (
          <>
            {!createGuildToggle && (
              <>
                <Grid item xs={12}>
                  <h3 style={{ paddingTop: "0px", textAlign: "center" }}>
                    {myTeamStatus}
                  </h3>
                </Grid>
                <Grid item xs={"auto"}>
                  <WarButton
                    size="small"
                    onClick={(e) => toggleCreateGuildToggle()}
                  >
                    Create a Guild
                  </WarButton>
                </Grid>
              </>
            )}

            {/* <h4 style={{ paddingTop: "0px", textAlign: "center" }}>Create a Team...</h4> */}

            {createGuildToggle && (
              <Grid item xs={"auto"}>
                <h4 style={{ paddingTop: "0px", textAlign: "center" }}>
                  Create a Guild...
                </h4>
                <CreateGuild
                  {...{
                    createNewGuild: (newTeamObj) => createNewGuild(newTeamObj),
                  }}
                ></CreateGuild>
              </Grid>
            )}
          </>
        )}
        {!_.isEmpty(myTeam) && (
          <>
            {sectionToLoad != "Chat" && (
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <div style={{ border: "0px solid" }}>
                  <p style={{ marginBottom: "0px" }}>{myTeam.name}</p>
                  <p style={{}}>Level: 1</p>
                  {!myTeam.imageUrl || myTeam.imageUrl == "" ? (
                    <>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "5px solid black",
                          borderRadius: "0px",
                        }}
                        src={questionCharacter}
                      />
                    </>
                  ) : (
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        border: "5px solid black",
                        borderRadius: "0px",
                      }}
                      src={myTeam.imageUrl}
                    />
                  )}
                </div>
              </Grid>
            )}

            <Grid
              item
              xs={"auto"}
              xs={12}
              style={{ textAlign: window.innerWidth > 500 ? "right" : "left" }}
            >
              {/* <WarButton onClick={() => { setSectionToLoad("Roster") }} size="small" >Current Roster</WarButton>
                        <WarButton onClick={() => { setSectionToLoad("Chat") }} size="small" >Chat</WarButton>
                        <WarButton onClick={() => { setSectionToLoad("Settings") }} size="small">Settings</WarButton> */}

              <NavBar
                style={{
                  borderRadius: "13px",
                  fontSize: window.innerWidth < 500 && "10px",
                  padding: window.innerWidth < 500 && "10px 10px",
                }}
                onClick={() => {
                  setSectionToLoad("Roster");
                }}
                size="small"
              >
                Guild Members
              </NavBar>
              <NavBar
                style={{
                  borderRadius: "13px",
                  fontSize: window.innerWidth < 500 && "10px",
                  padding: window.innerWidth < 500 && "10px 10px",
                }}
                onClick={() => {
                  setSectionToLoad("Chat");
                }}
                size="small"
              >
                Chat <sup style={{color:"#c90101", fontSize:"12px"}}>new!</sup>
              </NavBar>
              <NavBar
                style={{
                  borderRadius: "13px",
                  fontSize: window.innerWidth < 500 && "10px",
                  padding: window.innerWidth < 500 && "10px 10px",
                }}
                onClick={() => {
                  setSectionToLoad("Settings");
                }}
                size="small"
              >
                Settings <sup style={{color:"#c90101", fontSize:"12px"}}>new!</sup>
              </NavBar>
            </Grid>

            {/* ============= Begining of ROSTER ============= */}
            {sectionToLoad == "Roster" && (
              <>
                <Grid item xs={12}>
                  <h3 style={{ paddingTop: "0px", textAlign: "left" }}>
                    Guild Members
                  </h3>
                </Grid>
                <Grid item xs={12} style={{}}>
                  <CurrentTeam
                    {...{
                      myTeam: myTeam,
                      handleKick: (mem) => handleKick(mem),
                    }}
                  ></CurrentTeam>

                  <h6
                    style={{
                      paddingTop: "20px",
                      textAlign: "left",
                      fontSize: "14px",
                      border: "1px ",
                      width: "275px",
                    }}
                  >
                    Total Members: <span>{getMemberCount()} </span>
                  </h6>

                  <h6
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      border: "1px ",
                      width: "275px",
                    }}
                  >
                    Total Power Level:{" "}
                    <span>{numberWithCommas(getTotalPowerLevel())} </span>
                  </h6>
                </Grid>
              </>
            )}
            {/*============= Ending of ROSTER ---- STarting of CHAT  ============= */}
            {sectionToLoad == "Chat" && (
              <>
                <Grid item xs={12}>
                  <GuildChat
                    guildName={myTeam.name}
                    guildNick={nickName}
                    chatPicNum={chatPicNum}
                  />
                </Grid>
              </>
            )}

            {/*============= Ending of CHAT ---- Begining of SETTINGS =============*/}
            {sectionToLoad == "Settings" && (
              <>
                {myTeam.isOwner && (
                  <GuildEdit {...{ myTeam, guildUpdate, toast }} />
                )}

                <Grid item xs={12}>
                <h6 style={{ paddingTop: "10px", textAlign: "center" }}>
                    <WarButton
                      onClick={() => {
                        handleNameOpen();
                      }}
                      size="small"
                    >
                      Customize Chat
                    </WarButton>
                  </h6>
                  <h4 style={{ paddingTop: "10px", textAlign: "center" }}>
                    <WarButton
                      onClick={() => {
                        handleOpen();
                      }}
                      size="small"
                    >
                      {!myTeam.isOwner ? "Leave Guild" : "Disband Guild"}
                    </WarButton>
                  </h4>
                 
                </Grid>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="modalSizeAcceptDecline">
                    <p style={{ textAlign: "center" }}>
                      Are you sure you want to{" "}
                      {!myTeam.isOwner ? "leave guild" : "disband guild"}?
                    </p>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <Button
                        onClick={() => leaveTeamFunc()}
                        variant="outlined"
                        color="success"
                        style={{ marginRight: "10px" }}
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => handleClose()}
                        variant="outlined"
                        color="error"
                      >
                        No
                      </Button>
                    </div>
                  </Box>
                </Modal>
                <Modal
                  open={nameOpen}
                  onClose={handleNameClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Card sx={styleChangeNickName}>
                    
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        style={{
                          fontFamily: "cartoon",
                          fontSize: "28px",
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          error={nickName && nickName.length>10}
                          id="outlined-helperText"
                          label="Nick Name"
                          defaultValue={nickName}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          helperText="Max 10 char limit."
                          color="warning"
                          value={nickName}
                          onChange={(e) => handleNameChange(e)}
                        />
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        align="center"
                        style={{ fontFamily: "cartoon" }}
                      >
                        <p style={{ fontSize: "1.2rem",fontWeight:'normal' }}>Chat Image:</p>
                        {/* <img style={{ width: "50px", border: "2px solid black", borderRadius: "0px" }} src={teamObj.imageUrl} />  */}
                        <IconButton
                          sx={{ outline: "none" }}
                          onClick={() => {
                            setSelectedPicNum("");
                          }}
                        >
                          <img
                            style={
                              null == selectedPicNum || selectedPicNum == ""
                                ? {
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "5px",
                                    padding: "0px",
                                    borderRadius: "0",
                                    border: "4px lime solid",
                                  }
                                : {
                                    width: "50px",
                                    height: "50px",
                                    marginRight: "5px",
                                  }
                            }
                            src={questionCharacter}
                          />{" "}
                        </IconButton>

                        {data.walletOfOwner.map((tokenId) => (
                          <IconButton
                            sx={{ border: "none" }}
                            onClick={() => {
                              setSelectedPicNum(tokenId);
                            }}
                          >
                            <img
                              style={
                                tokenId == selectedPicNum
                                  ? {
                                      width: "50px",
                                      height: "50px",
                                      marginRight: "5px",
                                      padding: "0px",
                                      borderRadius: "0",
                                      border: "4px lime solid",
                                    }
                                  : {
                                      width: "50px",
                                      height: "50px",
                                      marginRight: "5px",
                                    }
                              }
                              src={
                                "https://fantomknights.art/images/" + tokenId
                              }
                            />
                          </IconButton>
                        ))}
                      </Typography>
                    </CardContent>
                    <CardActions style={{ justifyContent: "center" }}>
                      <WarButton disabled ={nickName && nickName.length>10} size="small" onClick={() => changeName()}>
                        Save
                      </WarButton>
                    </CardActions>
                  </Card>
                </Modal>
                {myTeam.isOwner && (
                  <>
                    <Grid item xs={12}>
                      <h3
                        class="loading"
                        style={{ paddingTop: "30px", textAlign: "center" }}
                      >
                        Pending Members{" "}
                      </h3>
                    </Grid>

                    {myTeam.members
                      .filter((mem) => mem.status == "Pending")
                      .map((pendingMem) => (
                        <Grid item xs={"auto"}>
                          <PendingMembers
                            {...{
                              member: pendingMem,
                              handleResponse: (response) =>
                                handleResponse(response, pendingMem),
                            }}
                          ></PendingMembers>
                        </Grid>
                      ))}

                    {myTeam.members.filter((mem) => mem.status == "Pending")
                      .length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        No current applicants!
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {/*============= End of SETTINGS =============*/}
          </>
        )}
      </Grid>

      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
};

export default MyGuild;
