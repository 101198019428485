import { ConstructionOutlined } from '@mui/icons-material';
import React, { useState, useRef, useEffect } from 'react';
import { Button, Search, ButtonView, SearchWizard, ButtonViewKnight } from '../ButtonElement';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
    PowerLevelBar,
    TokenNumber,
    Attribute,
    AttributeList,
    Video
}
    from './InfoElements';

import { GiCrossedSwords, GiWizardStaff } from "react-icons/gi";
// import {homeObjThree} from '../components/CollectionSection/Data';

import CollectionSection from '../CollectionSection';
// import {homeObjThree} from "../CollectionSection/Data"






const InfoSection = ({ lightBg, id, imgStart, topLine, lightText, headline, description, darkText, buttonLabel, alt, img, dark, primary ,imgW}) => {
    const [powerLevel, setPowerLevel] = useState(0);
    const [imageUrl, setImageUrl] = useState("");
    const [imageUrlW, setImageUrlW] = useState("");

    const [token, setToken] = useState(0);
    const [defaultState, setDefaultState] = useState(true);
    const [defaultStateW, setDefaultStateW] = useState(true);

    const [countdownEnded, setCountdownEnded] = useState(false);

    const [isKnightSelected, setIsKnightSelected] = useState(true);

    let interval = useRef();
    const startTimer = () => {

        // changeback
        const countdownDate = new Date(Date.UTC(2021, 11, 26, 17, 0, 0));
        // const countdownDate = new Date(Date.UTC(2021, 9, 29, 19,10,0));    

        interval = setInterval(() => {
            const now = new Date();
            const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
            const distance = countdownDate - nowUTC;
            if (distance < 0) {
                setCountdownEnded(true);
                clearInterval(interval.current);
            } else {

            }

        }, 1000);

    };
    useEffect(() => {
        startTimer();
        return () => {
            clearInterval(interval.current);
        };
    });


    const { REACT_APP_FANTOM_KNIGHTS_BACKEND_URL } = process.env;

    function handleSearch(e) {
        let id = e.target.value;
        // console.log(id)
        let num = Number(id);
        if (!isNaN(num) && num > 0 && num < 2002) {
            fetch(REACT_APP_FANTOM_KNIGHTS_BACKEND_URL + "/view/" + id)
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Something went wrong');
                    }
                })
                .then(
                    (result) => {
                        setPowerLevel(result.powerLevel);
                        setImageUrl(result.imageUrl);
                        setToken(id);
                        setDefaultState(false);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        setDefaultState(true);
                    }
                )


        } else {
            setDefaultState(true);
        }
    }

    function handleSearchWizard(e) {
        let id = e.target.value;
        // console.log(id)
        let num = Number(id);
        if (!isNaN(num) && num > 0 && num < 1112) {
            fetch(REACT_APP_FANTOM_KNIGHTS_BACKEND_URL + "/wizards/view/" + id)
                .then(res => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        throw new Error('Something went wrong');
                    }
                })
                .then(
                    (result) => {
                        setPowerLevel(result.powerLevel);
                        setImageUrlW(result.imageUrl);
                        setToken(id);
                        setDefaultStateW(false);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        setDefaultStateW(true);
                    }
                )


        } else {
            setDefaultStateW(true);
        }
    }

    function handleViewChange() {
        // console.log("handleViewChange")
        // console.log(isKnightSelected)
        setIsKnightSelected(!isKnightSelected)
        setDefaultStateW(true)
        setDefaultState(true)

    }
    function getSpecialImageUrl(token){
        if(token==1){
            return REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/wizards/images/0"
        }else if(token==5){
            return REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/wizards/images/00"
        }
    }

    return (
        <>
            <InfoContainer id={id} lightBg={lightBg} >

                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>


                                {isKnightSelected ?
                                    <>
                                        <TopLine>{topLine}</TopLine>
                                        <Heading lightText={lightText}>Search for a
                                            <span style={{ fontWeight: 600, color: "#FCA311" }}> knight</span></Heading>
                                        <Subtitle darkText={darkText}>{description}</Subtitle>
                                        <ButtonView onClick={handleViewChange}

                                        >
                                            <GiWizardStaff ></GiWizardStaff>
                                        </ButtonView>

                                        <BtnWrap>
                                            <Search to='home'
                                                smooth={true}
                                                placeholder="(1-2001)"
                                                duration={500}
                                                spy={true}
                                                exact="true"
                                                offset={-80}
                                                dark={dark ? 1 : 0}
                                                //dark2={dark2 ? 1 : 0} 
                                                primary={primary ? 1 : 0}
                                                onChange={(e) => handleSearch(e)}
                                            />


                                        </BtnWrap>
                                    </>
                                    :
                                    <>
                                        {/* 551A66 */}
                                        <TopLine style={{ color: "#6E317F" }}>{topLine}</TopLine>
                                        <Heading lightText={lightText}>Search for a
                                            <span style={{ fontWeight: 600, color: "#551A66" }}> wizard</span>
                                        </Heading>
                                        <Subtitle darkText={darkText}>(1-1111)</Subtitle>
                                        <ButtonViewKnight onClick={handleViewChange}>
                                            <GiCrossedSwords ></GiCrossedSwords>
                                        </ButtonViewKnight>

                                        <BtnWrap>
                                            <SearchWizard to='home'
                                                disabled={!countdownEnded}
                                                smooth={true}
                                                placeholder={countdownEnded ? "(1-1111)" : "Not yet!"}
                                                duration={500}
                                                spy={true}
                                                exact="true"
                                                offset={-80}
                                                dark={dark ? 1 : 0}
                                                //dark2={dark2 ? 1 : 0} 
                                                primary={primary ? 1 : 0}
                                                onChange={(e) => handleSearchWizard(e)}
                                            />
                                        </BtnWrap>
                                    </>
                                }




                            </TextWrapper>
                        </Column1>

                        {/* Character view screen */}
                        {isKnightSelected ?
                            <Column2>
                                {!defaultState && true &&
                                    <TokenNumber>Fantom Knight {token}</TokenNumber>}
                                <ImgWrap fillerNeeded={defaultState}>
                                    {!defaultState ? <Img src={imageUrl} alt={alt} /> : <Img src={img} alt={alt} />}
                                </ImgWrap>
                                {!defaultState &&
                                    <PowerLevelBar>Power Level: {powerLevel}
                                    </PowerLevelBar>}
                            </Column2>
                            :
                            <Column2>
                                {!defaultStateW && true &&
                                    <TokenNumber>Fantom Wizard {token}</TokenNumber>}
                                <ImgWrap fillerNeeded={defaultStateW}>
                                    {/* todo, revert back and get correct call */}
                                    {!defaultStateW ? (token==1||token==5? <Video  autoPlay loop src={getSpecialImageUrl(token)} alt={alt} ></Video>
                                        :<Img src={imageUrlW} alt={alt} />) : <Img src={imgW} alt={alt} />}
                                    {/* {<Img src={imgW} alt={alt} />} */} 

                                </ImgWrap>
                                {!defaultStateW &&
                                //  <PowerLevelBar>Power Level: ? 
                                //  </PowerLevelBar>
                                    <PowerLevelBar>Power Level: {powerLevel}
                                    </PowerLevelBar>
                                    
                                    }
                            </Column2>
                        }

                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
            {isKnightSelected ?
                <CollectionSection {...{ alt: 'Mystery', isKnight:isKnightSelected, imageURL:REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/images/" }} />
                :
                <>
                {countdownEnded && <CollectionSection {...{ alt: 'Mystery', isKnight:isKnightSelected, imageURL:REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/wizards/images/"}} />}
                </>
            }

        </>
    )
}

export default InfoSection;
