import styled from "styled-components";
import { Link as LinkS } from 'react-scroll';

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  height: ${({ enrolled }) => (enrolled ? "100%" : "980px")};
  margin-right: auto;
  margin-left: auto;
  padding: 24px 24px;
  justify-content: center;
`;

export const WarImage = styled.img`
  transition: height 1s;
  height: ${({ enrolled }) => (enrolled ? "500px" : "700px")};
  @media screen and (max-width: 480px) {
    height: ${({ enrolled }) => (enrolled ? "200px" : "250px")};
  }

  @media screen and (max-width: 320px) {
    height: ${({ enrolled }) => (enrolled ? "150px" : "200px")};
  }
`;

export const WarButton = styled.button`
  /* color: black; */
  /* font-size:38px; */
  margin-top: 20px;

  //border-radius: 50px;
  background: black;
  white-space: nowrap;
  /* width: 300px; */
  padding: ${({ big }) => (big ? "14px 48px" : "12px 50px")};
  color: white;
  font-size: ${({ fontBig }) => (fontBig ? "15px" : "15px")};
  border: white 2px solid;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ primary }) => (primary ? "#fff" : "#FCA311")};
    color: white;
  }
`;

export const WarLink = styled(LinkS)`
  /* color: black; */
  /* font-size:38px; */
  margin-top: 20px;

  //border-radius: 50px;
  background: white;
  white-space: nowrap;
  width: 300px;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: black !important;
  font-size: ${({ fontBig }) => (fontBig ? "26px" : "26px")};
  border: white 2px solid;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ primary }) => (primary ? "#fff" : "#FCA311")};
    color: white;
  }
`;

export const HeroBtnWrapper = styled.div`
  //margin-top: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 480px) {
    max-width: 350px;
  }

  @media screen and (max-width: 320px) {
    max-width: 300px;
  }
  color: white;

  p{
    color: red;
    padding-top: 10px;
  }
`;

export const TeamElement = styled.div`
  //margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 30px;
  width: 100%;
  color: black !important;
  padding: 50px 0px;
  @media screen and (max-width: 480px) {
    max-width: 350px;
  }
  background: white;

  @media screen and (max-width: 320px) {
    max-width: 300px;
  }
  color: white;
  h1 {
    padding-bottom: 10px;
  }
`;

export const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Stats = styled.div`
  margin: 0px 20px;
  table {
    text-align: left;
    width: 540px;
    @media screen and (max-width: 480px) {
      width:344px;
    }

    @media screen and (max-width: 320px) {
      width: 290px;
    }
    td {
      padding: 10px !important;
      font-size: ${({ enrolled }) => (enrolled ? "15px" : "17px")};
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .expand{
      padding-left: 40px !important;
      font-size:5px !important;
    }
    th {
      text-align: center;
      div{      border-bottom: white 2px solid;
        border-bottom: white 2px solid;

      }
      font-size: ${({ enrolled }) => (enrolled ? "40px" : "45px")};
      padding: 20px;
      color: #fca311;
    }
  }

  border: white 2px solid;
  border-radius: 10px;
`;




export const TeamWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  @media screen and (max-width: 480px) {
    justify-content: center;
  }

  @media screen and (max-width: 320px) {
    justify-content: center;
  }
  //align-items: center;
  padding: 50px;
`;

export const TeamStats = styled.div`
  display: inline-block;
  div {
    font-size: 20px;
  }
`;

export const PrizePool = styled.h2`
  color: #FCA311;
  font-size: 40px;
  @media screen and (max-width: 480px) {
    font-size: 30px;
    padding-top: 20px;
  }
  div{
    color: white;
    font-size: 15px;
    @media screen and (max-width: 480px) {
      font-size: 15px;
    }
  }

`
export const TeamControlsWrapper = styled.div`
  display:flex;
  text-align:left;
  justify-content:center;
  padding-top:40px;
  flex-flow: wrap;
  @media screen and (max-width: 980px) {
      div{
        padding-top: 20px;
        padding-bottom: 20px;
      }
  }
  
`;

export const TeamControls = styled.div`
  display: flex;
  border: 2px solid;
  height: 276px;
  padding: 20px;
  border-radius: 15px;
  flex-direction: column;
  text-align: center;

  @media screen and (max-width: 980px) {
    height: 100%;
  }

  @media screen and (max-width: 480px) {
    height: 100%;
    max-width: 460px;
  }

  @media screen and (max-width: 320px) {
    height: 100%;
    max-width: 300px;

  }
  //width: 600px;
  h1 {
    padding-bottom: 10px;
    padding-top: 10px;
    color: #fca311;
    border-bottom: 2px solid white;

  }
`;
export const SaveWrapper = styled.div`
  h4{
    margin-left: 30px;
    @media screen and (max-width: 980px) {
      margin-left: 0px;
    }
  }

`
export const Save = styled.button`
  width: 250px;
  height: 55px;
  font-size: 20px;
  border-radius: 2px;
  background: #fca311;
  margin-left: 30px;
  @media screen and (max-width: 980px) {
      margin-left: 0px;
    }
`;

export const RaceSelect = styled.select`
  width: 200px;
  padding-left: 15px;
  height: 55px;
  font-size: 20px;
  border-radius: 2px;
  background: #fca311;
  //margin-top: 30px;
  option {
    width: 300px;
  }
`;

export const Container = styled.div`
  max-width: 300px;
  padding: 10px;
`;


export const Img = styled.img`
  width: 100%;
  margin: 0 0 0px 0;
  padding-right: 0;
`;


export const TokenNumber = styled.div`

  background: whitesmoke;
  color: black;
  font-size: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  padding: 10px;
  text-align: center;
  @media screen and (max-width: 560px) {
    font-size: 15px;
  }
`;

export const PowerLevelBar = styled.div`
  background: whitesmoke;
  color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  font-size: 20px;
  padding: 10px;
  text-align: center;
  
  @media screen and (max-width: 560px) {
    font-size: 15px;
  }
`;

export const ImgWrap = styled.div`
  max-width: 500px;
  border-left: ${({ fillerNeeded }) =>
    fillerNeeded ? "none" : "15px solid whitesmoke"};
  border-right: ${({ fillerNeeded }) =>
    fillerNeeded ? "none" : "15px solid whitesmoke"};
  margin-bottom: ${({ fillerNeeded }) => (fillerNeeded ? "65px" : "0px")};
  margin-top: ${({ fillerNeeded }) => (fillerNeeded ? "65px" : "0px")};
  @media screen and (max-width: 560px) {
    max-width: 300px;
  }
`;

export const ImageWrappers = styled.div`
  color: black;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;
  @media screen and (max-width: 560px) {
    font-size: 25px;
  }
`;
export const Selector = styled.div`
    width: 300px;
    padding-left:50px;
    text-align: left;
    input{
        margin-top: 15px;
    }
`;
