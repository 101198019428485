
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TeamCard from '../Teams/TeamCard'
import Modal from '@mui/material/Modal';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { Button, Card, Image } from 'semantic-ui-react'


import PendingMembers from "./PendingMembers"
import BasicTable from "./CurrentTeam"


//  Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import useWindowDimensions from '../../useWindowDimensions'

import './MyTeam.css';

import CurrentTeam from "../MyTeam/CurrentTeam"

import CreateTeam from "../MyTeam/CreateTeam";

import {
    WarButton
} from "./TeamElements";
import _ from "lodash";
import { acceptMember, createTeam, declineMember, deleteTeam, getMyCurrentTeam, getTeams, kickMember, leaveTeam } from "../../../api";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const style = {
    position: 'relative',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
    // width: '50%',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid',
    boxShadow: 24,
    p: 4,
    // width:"40%"


};

const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9)
    , createData("Gingerbread", 356, 16.0, 49, 3.9)
    , createData("Gingerbread", 356, 16.0, 49, 3.9)
    , createData("Gingerbread", 356, 16.0, 49, 3.9)

];


const MyTeam = () => {

    const [myTeam, setMyTeam] = useState({});
    const [renderChange, setRenderChange] = useState(0);
    const [myTeamStatus, setMyTeamStatus] = useState("You are not part of a team")

    const Item = styled(Paper)(({ theme }) => ({
        // ...theme.typography.body2,
        // padding: theme.spacing(1),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    }));

    const data = useSelector((state) => state.data);

    const { REACT_APP_FANTOM_KNIGHTS_BACKEND_URL } = process.env;

    const numbers = [1, 2, 3]

    const [open, setOpen] = useState(false);

    const { height, width } = useWindowDimensions();

    const [createTeamToggle, setCreateTeamToggle] = useState(false);



    function getTotalPowerLevel() {
        var totalPwerLevel = 0;
        totalPwerLevel += myTeam.ownerPowerLevel;
        myTeam.members.filter(mem => mem.status == "Joined").map(mem => {
            totalPwerLevel += mem.powerLevel;
        });
        return totalPwerLevel.toFixed(0);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //TODO: Change to return only related team for address, need api created for it
    async function getMyTeam() {
        try {
            let response = await getMyCurrentTeam(data.account);
            if (response.status == 201) {
                let team = response.data;
                let memInfo = team.members.find(mem => {
                    return mem.walletAddress.toString().toLowerCase() === data.account.toString().toLowerCase();
                });
                if (memInfo !== undefined) {
                    if (memInfo.status == "Joined") {
                        team.isOwner = false;
                        setMyTeam(team);
                    } else {
                        setMyTeamStatus(`You have already applied to the team "${team.name}" but have not been accepted!`)
                    }

                } else if (team.ownerWalletAddress.toString().toLowerCase() === data.account.toString().toLowerCase()) {
                    team.isOwner = true;
                    setMyTeam(team);
                }

            } else {
                throw new Error('Something went wrong');
            }
        } catch (error) {
            setMyTeam({});

            // console.log(error);
        }
    }

    useEffect(() => {

        getMyTeam()
        // console.log(renderChange);


    }, [renderChange]);


    function toggleCreateTeamToggle() {
        setCreateTeamToggle(!createTeamToggle)
    }
    function leaveTeamFunc() {
        if (myTeam.isOwner) {
            deleteTeam(myTeam.name, data.account).then(res => {
                getMyTeam().then(() => {
                    setRenderChange(renderChange + 1);
                    setOpen(false);

                });
            });
        } else {
            leaveTeam(myTeam.name, data.account, data.walletOfOwner).then(res => {
                getMyTeam().then(() => {
                    setRenderChange(renderChange + 1);
                    setOpen(false);

                });

            });
        }
    }
    function handleResponse(response, pendingMember) {
        if (response) {
            acceptMember(pendingMember.walletAddress, myTeam.name, data.account).then(res => {
                getMyTeam().then(() => {
                    setRenderChange(renderChange + 1);
                });

            });
        } else {
            declineMember(pendingMember.walletAddress, myTeam.name, data.account).then(res => {
                getMyTeam().then(() => {
                    setRenderChange(renderChange + 1);
                });

            });
        }
    }
    function handleKick(member) {
        kickMember(member.walletAddress, myTeam.name, data.account).then(res => {
            getMyTeam().then(() => {
                setRenderChange(renderChange + 1);
            });

        });
    }
    function createNewTeam(newTeamObj) {
        createTeam(newTeamObj.teamName, newTeamObj.teamDesc, data.account).then(res => {
            // console.log(res.status)

            if (res.status == 201) {
                setCreateTeamToggle(false);
                getMyTeam().then(
                    toast.success("Team succesfully created!")

                );
            }

        })
            .catch(err => {
                toast.error(err.response.data.message + "!")
            })

    }
    function handleClose() {
        setOpen(false);
    }
    function handleOpen() {
        setOpen(true);
    }
    
    return (
        // <>
        //     {numbers.map(num => (
        //         <TeamCard></TeamCard>
        //     ))}
        // </>

        // <div> hello</div>
        <>

            {/* <h4 class="loading">Pending Members </h4> */}
            {/* <Grid container spacing={2} maxHeight={600} style={{justifyContent:"center", marginTop:"0px"}}> */}

            {/* <Grid item xs={"auto"}> */}
            {/* <BasicTable></BasicTable> */}
            {/* </Grid> */}


            {/* </Grid> */}




            <Grid container spacing={2} maxHeight={window.innerWidth > 800 ? 600 : 370} style={{ justifyContent: "center", marginTop: "0px" }}>
                {_.isEmpty(myTeam) && <>
                    {!createTeamToggle && <><Grid item xs={12} >
                        <h3 style={{ paddingTop: "0px", textAlign: "center" }}>{myTeamStatus}</h3>
                    </Grid>
                        <Grid item xs={"auto"} >
                            <WarButton size="small" onClick={(e) => toggleCreateTeamToggle()}>Create a team</WarButton>
                        </Grid>
                    </>
                    }

                    {/* <h4 style={{ paddingTop: "0px", textAlign: "center" }}>Create a Team...</h4> */}


                    {createTeamToggle && <Grid item xs={"auto"} >
                        <h4 style={{ paddingTop: "0px", textAlign: "center" }}>Create a Team...</h4>
                        <CreateTeam {...{ createNewTeam: (newTeamObj) => createNewTeam(newTeamObj) }}></CreateTeam>

                    </Grid>}
                </>}
                {!_.isEmpty(myTeam) && <>

                    <Grid item xs={12} >
                        <h3 style={{ paddingTop: "0px", textAlign: "center" }}>Current Roster</h3>


                    </Grid>
                    <Grid item xs={12} style={{}}>
                        <CurrentTeam {...{ myTeam: myTeam, handleKick: (mem) => handleKick(mem) }}></CurrentTeam>
                        <h6

                            style={{ paddingTop: "20px", textAlign: "left", fontSize: "14px", border: "1px ", width: "275px" }}>
                            Total Power Level: <span >{numberWithCommas(getTotalPowerLevel())} </span></h6>
                        {getTotalPowerLevel() > 4000000 &&
                            <h6
                                data-tip="If you have more than 4M power level by yourself, avoid joining a team!"
                                style={{ paddingTop: "0px", textAlign: "left", color: "red", fontSize: "10px", width: "275px" }}> TEAM POWER LEVEL IS CAPPED AT 4M</h6>}
                        <ReactTooltip />
                    </Grid>

                    <Grid item xs={12} >
                        <h4 style={{ paddingTop: "30px", textAlign: "center" }}><WarButton onClick={() => { handleOpen() }} size="small" >{!myTeam.isOwner ? "Leave Team" : "Disband Team"}</WarButton></h4>
                    </Grid>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >

                        <Box sx={style} className="modalSizeAcceptDecline" >
                            <p style={{ textAlign: "center" }}>Are you sure you want to {!myTeam.isOwner ? "leave team" : "disband team"}?</p>
                            <div style={{ justifyContent: "center", display: "flex" }}>

                                <Button onClick={() => leaveTeamFunc()} variant="outlined" color="success" style={{ marginRight: "10px" }}>
                                    Yes
                                </Button>
                                <Button onClick={() => handleClose()} variant="outlined" color="error" >
                                    No
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                    {myTeam.isOwner && <>

                        <Grid item xs={12} >
                            <h3 class="loading" style={{ paddingTop: "30px", textAlign: "center" }}>Pending Members </h3>
                        </Grid>

                        {myTeam.members.filter(mem => mem.status == "Pending").map(pendingMem => (

                            <Grid item xs={"auto"}>
                                <PendingMembers {...{ member: pendingMem, handleResponse: (response) => handleResponse(response, pendingMem) }}  ></PendingMembers>
                            </Grid>

                        ))}

                        {myTeam.members.filter(mem => mem.status == "Pending").length == 0 && <div style={{ textAlign: "center" }}>No current applicants!</div>}

                    </>}
                </>}

            </Grid>

            <ToastContainer
                position="top-right"
                autoClose={5000}
            />



        </>


    )
}

export default MyTeam;
