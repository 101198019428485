import styled from 'styled-components';

export const InfoContainer = styled.div`
    color:#fff;
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#010606')};

    @media screen and (max-width: 768px) {
        padding: 100px 0;

    }
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
    align-items: center;
    //border: 10px solid black;
`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`
export const Header = styled.h1`
   color: black;
   font-size:38px;
   margin-top: 20px;
   text-align: center;
`



export const ImageWrappers = styled.div`
   color: black;
    justify-content: center;
   display:flex;
   flex-flow: row wrap;
   overflow: hidden;
   @media screen and (max-width: 560px) {
    font-size: 25px;
    }
`

export const TopLine = styled.p`
    color: #FCA311;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ darkText }) => (darkText? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 500px;
    border-left: ${({fillerNeeded}) => (fillerNeeded ?'none' : '15px solid black')}; 
    border-right: ${({fillerNeeded}) => (fillerNeeded ?'none' : '15px solid black')}; 
    margin-bottom:  ${({fillerNeeded}) => (fillerNeeded ?'65px' : '0px')};
    margin-top:  ${({fillerNeeded}) => (fillerNeeded ?'65px' : '0px')};
    @media screen and (max-width: 560px) 
    {
        max-width: 300px;
    }
`

export const Img = styled.img`
    width: 100%;
    margin: 0 0 0px 0;
    padding-right: 0;
`

export const PowerLevelBar = styled.div`
    background: black;
    color: white;
    font-size: 30px;
    padding: 10px;
    text-align: center;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    @media screen and (max-width: 560px) 
    {
        font-size: 25px;
    }
`

export const TokenNumber = styled.div`
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    background: black;
    color: white;
    font-size: 30px;
    padding: 10px;
    text-align: center;
    @media screen and (max-width: 560px) 
    {
        font-size: 25px;
    }

`

export const AttributeList = styled.ul`
    font-size: 10px;
    text-align: left;
`

export const Attribute = styled.li`
        font-size: 10px;


`

export const Container = styled.div`
    //min-width: 400px;
    padding: 10px;
`

export const ShowCollection = styled.button`


    /* color: black; */
    /* font-size:38px; */
    margin-top: 20px;
    
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 300px;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    font-size: ${({fontBig}) => (fontBig ? '26px' : '26px')};
    border: white 2px solid;
    cursor: pointer;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({primary}) => (primary ?'#fff' : '#FCA311')};
        color: black;
    }
`

export const ShowCollectionWizard = styled.button`


    /* color: black; */
    /* font-size:38px; */
    margin-top: 20px;
    
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 300px;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    font-size: ${({fontBig}) => (fontBig ? '26px' : '26px')};
    border: white 2px solid;
    cursor: pointer;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({primary}) => (primary ?'#fff' : '#551A66')};
        color: white;
        border:1px solid black;
    }
`