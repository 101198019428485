import styled from 'styled-components';
import {MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 969px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 560px) 
    {
        height: 800px;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background: linear-gradient(180deg, rgb(0 0 0 / 0.2), rgb(0 0 0 / 0.6)), linear-gradient(180deg, rgb( 0 0 0 / 0.2), transparent);
        z-index: 2;
    }
`
//add before styles to HeroContainer
export const NFTCycle = styled.img`
    text-align: center;
    width: 450px;
    @media screen and (max-width: 560px) 
    {
        width:300px;
    }

`
export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
    z-index: 3;
    padding-top: 100px;
    @media screen and (max-width: 560px) 
    {
        padding-top: 0px;
    }
    max-width: 1200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
`





export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 38px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }

    @media screen and (max-width: 320px) 
    {
        font-size: 20px;
    }
`

export const HeroP = styled.p`
    margin-bottom: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    //margin-top: 32px;
    display: flex;
    flex-direction: 'row' ;
    align-items: center;
    @media screen and (max-width: 480px) {
        max-width: 350px;
    }

    @media screen and (max-width: 320px) 
    {
        max-width: 300px;
    }
    color: white;

`

export const MintRemaining = styled.div`
    font-size: 20px;
    color: white;
    padding-top: 5px;
    justify-content: space-between;
    display:flex;
    text-align: left;
    width: 100%;
    letter-spacing: 2px;
    -webkit-text-stroke:1px black;
    @media screen and (max-width: 480px) {
        max-width: 350px;
    }
`

export const RareMountToggle = styled.div`
    font-size: 20px;
    color: white;
    padding-top: 5px;
    justify-content: space-between;
    display:flex;
    text-align: left;
    width: 100%;
    letter-spacing: 2px;
    -webkit-text-stroke:0.5px black;
`


export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
    
`

export const dottedElement = styled.p`
    :after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}

    @keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
        color: white;
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
        text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}}


`