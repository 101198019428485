const initialState = {
    loading: false,
    // name: "FantomKnights",
    name: "FantomWizards",
    totalSupply: 0,
    cost: 66000000000000000000,
    // cost: 99000000000000000000,
    error: false,
    errorMsg: "",
    maxSupply: 0,
    walletOfOwner:[],
    account:"",
    walletOfOwnerWizard:[],
    walletWithMount:[]

  };


const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_DATA_REQUEST":
          return {
            ...state,
            loading: true,
            error: false,
            errorMsg: "",
          };
        case "CHECK_DATA_SUCCESS":
          // console.log("CHECK_DATA_SUCCESS")
          // console.log(action.payload.walletOfOwnerMount)
          return {
            ...state,
            loading: false,
            name: action.payload.name,
            totalSupply: action.payload.totalSupply,
            cost: action.payload.cost,
            error: false,
            errorMsg: "",
            maxSupply: action.payload.maxSupply,
            walletOfOwner: action.payload.walletOfOwner,
            account: action.payload.account,
            walletOfOwnerWizard:action.payload.walletOfOwnerWizard,
            walletWithMount:action.payload.walletOfOwnerMount
            
          };
        case "CHECK_DATA_FAILED":
          return {
            ...state,
            loading: false,
            error: true,
            errorMsg: action.payload,
          };
        default:
          return state;
      }

}

export default dataReducer;
