import React, { useState } from 'react';
import Navbar from  '../components/Navbar';
import Sidebar from  '../components/Sidebar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import {homeObjTwo,charityObj} from '../components/InfoSection/Data';
import Footer from '../components/Footer';
import RoadMapSection from '../components/RoadMapSection';
import CharitySection from '../components/CharitySection/charity';
import WarSection from '../components/WarSection';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroSection />
            <InfoSection {...homeObjTwo}/>
            <WarSection/>
            
            <CharitySection {...charityObj} />

            <RoadMapSection />
            <Footer />
        </>
    );
}

export default Home;
