import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";


// combine all the reducers into one root reducer --> Common practice to avoid too many reducers
const rootReducer = combineReducers({
    blockchain: blockchainReducer,
    data: dataReducer,
  });

// middleware required by redux/react to allow no reloads on page (I believe)
const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));


// configure the store, where all the data is stored.
const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};


const store = configureStore();

export default store;
