import React,{useState} from 'react';

import { SidebarContainer,
    CloseIcon,
    Icon,
    SidebarWrapper,
    SidebarLink,
    SidebarMenu,
    SideBtnWrap,
    SidebarRoute, } from './sideBarElements';
    
const Sidebar = ({isOpen, toggle}) => {
    const [ hover, setHover ] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }
    return (
        <>
            <SidebarContainer isOpen={isOpen} onClick={toggle}>
                <Icon onClick={toggle}>
                    <CloseIcon />
                </Icon>
                <SidebarWrapper>
                    <SidebarMenu>
                      
                        <SidebarLink to='home' onClick={toggle}>
                            Mint
                        </SidebarLink>
                        <SidebarLink to='view' onClick={toggle}>
                            View
                        </SidebarLink>
                        <SidebarLink onMouseEnter={onHover} onMouseLeave={onHover} to='war' onClick={toggle}>
                        WAR
                        </SidebarLink>
                        
                        <SidebarLink to='charity' onClick={toggle}>
                            Charity
                        </SidebarLink>
                        <SidebarLink to='roadmap' onClick={toggle}>
                            Roadmap
                        </SidebarLink>
                        
                        
                    </SidebarMenu>
                   
                </SidebarWrapper>
            </SidebarContainer>
        </>
    );
};

export default Sidebar;
