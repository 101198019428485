import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './pages';

import { getNfts } from './redux-backup/actions/nfts'
import './App.css';

const App = () => {

    // const dispatch = useDispatch();

    // const nfts = useSelector((state)=>state.nfts)

    // console.log(nfts)

    // useEffect(()=>{
    //     console.log("useEffect :: Entered")
    //     dispatch(getNfts())
    // },[dispatch])

    return (
        <Router>
          <Switch>
            <Route path='/' component={Home} exact/>
          </Switch>
        </Router>
      );
}

export default App;