import axios from 'axios'
import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'
const url = 'http://localhost:5000/nft'


let {REACT_APP_FANTOM_KNIGHTS_BACKEND_URL, REACT_APP_ACCESS_TOKEN_SECRET} = process.env;

// Not used 
export const fetchNfts = () => {
    
    return  axios.get(url)

}


//  Fetch JWT token on wallet connect
export const fetchAccessToken = (userWalletAddress) => {
    // same token on backend used to decrypt the message (This way no one can sign up for other mans)
    let encryptedWallet = AES.encrypt(userWalletAddress+"",REACT_APP_ACCESS_TOKEN_SECRET+ "").toString()
    return  axios.post(REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/auth/walletConnectAuthentication" , {
        walletAddress: encryptedWallet
    })

}
 
/**
 * participantObject = 
 *  {
    "walletAddress": "0x1c6c476117fc7cd39ee7b9fc6e76946d2cf39bc7",
    "raceBonus": "Shadow",
    "leadershipMode":"Calm"
}
 */

export const addWizardMint = (walletAddress,numMinted) => {
    // add authorization JWT header
    let jwtToken = localStorage.getItem('accessToken')
    // let headers = { 'authorization': 'Bearer ' + jwtToken }

    // Encrypt payload
    let encryptedData = AES.encrypt(JSON.stringify({walletAddress:walletAddress,numMinted:numMinted})+"",REACT_APP_ACCESS_TOKEN_SECRET+ "").toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/addWizardMint", 
        {data: encryptedData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}



export const addWarParticipant = (participantObject) => {
    // add authorization JWT header
    let jwtToken = localStorage.getItem('accessToken')
    // let headers = { 'authorization': 'Bearer ' + jwtToken }

    // Encrypt payload
    let encryptedParticipantObject = AES.encrypt(JSON.stringify(participantObject)+"",REACT_APP_ACCESS_TOKEN_SECRET+ "").toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/addWarParticipant", 
        {participantData: encryptedParticipantObject},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}


export const getMultipleTokens = (listOfIds) => {

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/view", 
        {idsToFetch: listOfIds}
        )
}



export const getMultipleTokensWizard = (listOfIds) => {

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/wizards/view", 
        {idsToFetch: listOfIds}
        )
}


export const getMultipleTokensMount = (listOfIds) => {

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/mounts/view", 
        // "http://localhost:5000/mounts/view", 
        {idsToFetch: listOfIds}
        )
}



export const getWarParticipantDetails = (participantObject) => {
    // add authorization JWT header
    let jwtToken = localStorage.getItem('accessToken')
    // let headers = { 'authorization': 'Bearer ' + jwtToken }

    // Encrypt payload
    let encryptedParticipantObject = AES.encrypt(JSON.stringify(participantObject)+"",REACT_APP_ACCESS_TOKEN_SECRET+ "").toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/getWarParticipantDetails", 
        {participantData: encryptedParticipantObject},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}


//======================Teams APIs================================
//walletOfOwner is current users wallet address

export const getTeams = (sortType) => {
    return  axios.get(REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/getTeams/?sort="+sortType)
}

export const getGuilds = (sortType) => {
    return  axios.get(REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/getGuilds/?sort="+sortType)
}


export const getMyCurrentTeam = (walletAddress) => {
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = {walletAddress:walletAddress};
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/getMyTeam", 
        {teamData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const getMyCurrentGuild = (walletAddress) => {
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = {walletAddress:walletAddress};
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/getMyGuild", 
        {guildData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const createTeam = (teamName,desc,walletOfOwner) => {
    let jwtToken = localStorage.getItem('accessToken')


    let teamObject = {ownerWalletAddress:walletOfOwner,name:teamName,desc:desc}
    // console.log(teamObject)
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/createTeam", 
        {teamData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const createGuild = (guildName,desc,walletOfOwner, imageURL) => {
    let jwtToken = localStorage.getItem('accessToken')


    let teamObject = {ownerWalletAddress:walletOfOwner,name:guildName,desc:desc, imageUrl:imageURL}
    // console.log(teamObject)
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()

    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/createGuild", 
        {guildData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const handleTeam =(teamName,walletOfOwner,nfts,action)=>{
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = { "walletAddress": walletOfOwner, "nfts": nfts, "name": teamName, "action":action }
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()
    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/handleTeamUpdate", 
        {teamData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const handleGuild =(teamName,walletOfOwner,nfts,action)=>{
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = { "walletAddress": walletOfOwner, "nfts": nfts, "name": teamName, "action":action }
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()
    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/handleGuildUpdate", 
        {guildData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const joinTeam = (teamName,walletOfOwner,nfts) => {
    return handleTeam(teamName,walletOfOwner,nfts,"Apply");
}

export const joinGuild = (teamName,walletOfOwner,nfts) => {
    return handleGuild(teamName,walletOfOwner,nfts,"Apply");
}

export const leaveTeam = (teamName,walletOfOwner,nfts) => {
    return handleTeam(teamName,walletOfOwner,nfts,"Leave");
}

export const leaveGuild = (teamName,walletOfOwner,nfts) => {
    return handleGuild(teamName,walletOfOwner,nfts,"Leave");
}

export const manageTeam = (applicantAddr,action,teamName,walletOfOwner)=>{
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = { "applicant": applicantAddr, "walletAddress": walletOfOwner, "name": teamName, "response":action }
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()
    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/manageTeam", 
        {teamData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}
export const manageGuild = (applicantAddr,action,teamName,walletOfOwner)=>{
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = { "applicant": applicantAddr, "walletAddress": walletOfOwner, "name": teamName, "response":action }
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()
    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/manageGuild", 
        {guildData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const uploadImageToCloud = (formData) =>{
                            
    return axios.post("https://api.cloudinary.com/v1_1/dgwakgnw1/image/upload/", formData)
}

//applicantAddr is wallet address  of member you are accepting/declining/kicking
export const acceptMember = (applicantAddr,teamName,walletOfOwner) => {
    return manageTeam(applicantAddr,"Approve",teamName,walletOfOwner);
}
export const acceptGuildMember = (applicantAddr,teamName,walletOfOwner) => {
    return manageGuild(applicantAddr,"Approve",teamName,walletOfOwner);
}

export const declineMember = (applicantAddr,teamName,walletOfOwner) => {
    return manageTeam(applicantAddr,"Decline",teamName,walletOfOwner);
}
export const declineGuildMember = (applicantAddr,teamName,walletOfOwner) => {
    return manageGuild(applicantAddr,"Decline",teamName,walletOfOwner);
}

export const kickMember = (applicantAddr,teamName,walletOfOwner) => {
    return manageTeam(applicantAddr,"Kick",teamName,walletOfOwner);
}

export const kickGuildMember = (applicantAddr,teamName,walletOfOwner) => {
    return manageGuild(applicantAddr,"Kick",teamName,walletOfOwner);
}

export const deleteTeam = (teamName,walletOfOwner) => {
    return manageTeam(walletOfOwner,"Delete",teamName,walletOfOwner);
}

export const deleteGuild = (teamName,walletOfOwner) => {
    return manageGuild(walletOfOwner,"Delete",teamName,walletOfOwner);
}
export const changeNickNameChat =(teamName,walletOfOwner,nickName,chatPicNum)=>{
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = { "walletAddress": walletOfOwner, "nickName": nickName, "name": teamName,chatPicNum }
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()
    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/changeNickName", 
        {guildData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}

export const updateGuildCall = (teamName,action,newVal,walletAddress)=>{
    let jwtToken = localStorage.getItem('accessToken');
    let teamObject = { newVal, "name": teamName, action,walletAddress }
    // Encrypt payload
    let encryptedTeamData = AES.encrypt(JSON.stringify(teamObject),REACT_APP_ACCESS_TOKEN_SECRET).toString()
    return  axios.post(
        REACT_APP_FANTOM_KNIGHTS_BACKEND_URL+"/war/updateGuildInfo", 
        {guildData: encryptedTeamData},
        {headers:{
            "authorization":  'Bearer ' + jwtToken
        }}
        )
}
