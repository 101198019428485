import React, { useRef, useState,useEffect } from 'react';
import './chat.css';
import {FaPaperPlane  } from 'react-icons/fa';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';


import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { NordicWalkingOutlined } from '@mui/icons-material';

import questionCharacter from '../../../images/question.png'


const devConfig = {
  apiKey: "AIzaSyDe_Sq87N5T-HZW014IbHH7NN5eQq6WqXM",
  authDomain: "chat-test-35c68.firebaseapp.com",
  projectId: "chat-test-35c68",
  storageBucket: "chat-test-35c68.appspot.com",
  messagingSenderId: "719223658050",
  appId: "1:719223658050:web:7febb396eb48737fc8bfdb"
}

const prodConfig = {

}
firebase.initializeApp(devConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();


function GuildChat(props) {
  const guildName =props.guildName;
  const nick =props.guildNick;
  const chatPicNum = props.chatPicNum;
  const [user] = useAuthState(auth);
  function signIn(){
    let token = localStorage.getItem("chatToken");
    auth.signInWithCustomToken(token)
      .then((userCredential) => {

        // Signed in
        const user = userCredential.user;
      })
      .catch((error) => {

        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }

  useEffect(() => {
    signIn();
    return () => {
    }
  }, []);

  return (
    <div className="chat">
    
      <section>
        {user ? <ChatRoom chatPicNum={chatPicNum} name={nick} guildName={guildName}/> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {



  return (
    <>
      <p className="sign-in" >Re-connect your wallet</p>
    </>
  )

}




function ChatRoom(props) {

  const dummy = useRef();
  const guildName =props.guildName;
  const name =props.name?props.name:"";
  const chatPicNum = props.chatPicNum;

  const messagesRef = firestore.collection('messages');
  const query = messagesRef.where('guildName','==',guildName).limit(50);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  useEffect(() => {
    dummy.current.scrollIntoView();
  }, [messages?.length]);
  function messageProcessor(messagesToFix){
    var currDate;
    var currUID;
    
    messagesToFix.sort((a,b)=>a.createdAt-b.createdAt).map(msg=>{
      if(!currDate || !currUID){
        if(msg.createdAt)
        currDate=msg.createdAt.toDate();
        currUID=msg.uid;
      }else{
        if(currUID==msg.uid && (Math.abs(currDate - msg.createdAt.toDate()) / 36e5)<1){
          msg.hideInfo=true;
        }
        currDate=msg.createdAt.toDate();
        currUID=msg.uid;
      }
    });
    dummy.current.scrollIntoView({ behavior: 'smooth' });

    return messagesToFix;
    
  }
  const sendMessage = async (e) => {
    e.preventDefault();
    const { uid, photoURL } = auth.currentUser;
    const picUrl =chatPicNum?"https://fantomknights.art/images/" + chatPicNum:"";
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL:picUrl,
      guildName,
      name
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {/* {messages && messages.sort((a,b)=>a.createdAt-b.createdAt).map(msg => <ChatMessage key={msg.id} message={msg} uid={auth.currentUser.uid} />)} */}
      {messages && messageProcessor(messages).map(msg => <ChatMessage hideInfo={msg.hideInfo} key={msg.id} message={msg} uid={msg.uid} />)}

      <span ref={dummy}></span>

    </main>

    <form style={!formValue?{borderColor:'#00000036'}:{borderColor:'black'}} onSubmit={sendMessage}>

      <input value={formValue} maxLength={250} onChange={(e) => setFormValue(e.target.value)} placeholder="Type here..." />

      <button type="submit" disabled={!formValue}><FaPaperPlane style={{color: "white"}}/></button>

    </form>
  </>)
}

function shortenWallet(walletAddress){
  // console.log(walletAddress);
  return walletAddress.substring(0,4) + "..." + walletAddress.substring(walletAddress.length-4)
}

function ChatMessage(props) {
  
  const { text, uid, photoURL, createdAt,name } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`}>
    {!props.hideInfo && 
      <img src={photoURL || questionCharacter} />
      }
      {props.hideInfo && 
      <div style={{minWidth:'50px',minHeight:'50px'}} />
      }

    
      <div>
      { (messageClass=='sent' || window.innerWidth <= 480 ) &&!props.hideInfo  && <sup class='timestamp'> {!props.hideInfo && createdAt?new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(createdAt.toDate()):""} <span className="profileName">{!props.hideInfo?(name?name:shortenWallet(props.uid)):""}</span> </sup>}
      { (messageClass=='received' && window.innerWidth > 480) && !props.hideInfo&& <sup class='timestamp'><span className="profileName">{!props.hideInfo?(name?name:shortenWallet(props.uid)):""}</span> {!props.hideInfo && createdAt?new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(createdAt.toDate()):""}  </sup>}
      <p>{text}</p>
      </div>
      
    </div>
  </>)
}


export default GuildChat;
