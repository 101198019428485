import styled from "styled-components";
import { Link as LinkS } from 'react-scroll';

export const WarButton = styled.button`
  /* color: black; */
  /* font-size:38px; */
  margin-top: 20px;

  //border-radius: 50px;
  background: black;
  white-space: nowrap;
  /* width: 300px; */
  padding: ${({ big }) => (big ? "14px 48px" : "12px 50px")};
  color: white;
  font-size: ${({ fontBig }) => (fontBig ? "15px" : "15px")};
  border: white 2px solid;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ primary }) => (primary ? "#fff" : "#FCA311")};
    color: white;
  }
`;


export const NavBar = styled.button`
  /* color: black; */
  /* font-size:38px; */
  margin-top: 20px;
  border-radius:"100px";
  //border-radius: 50px;
  background: black;
  white-space: nowrap;
  /* width: 300px; */
  padding: ${({ big }) => (big ? "5px 20px" : "10px 30px")};
  color: white;
  font-size: ${({ fontBig }) => (fontBig ? "15px" : "15px")};
  border: white 2px solid;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  transition: all 0.2s ease-in-out;
 
  &:hover {
    background: ${({ primary }) => (primary ? "#fff" : "#FCA311")};
    color: white;
  }
`;