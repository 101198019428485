import React from 'react';
import { Button,Search } from '../ButtonElement';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1, 
    Column2,
    TextWrapper,
    TopLine, 
    Heading, 
    Subtitle, 
    BtnWrap,
    ImgWrap,
    Img,
    CharityH1
}
from './CharitySectionElements';
import {  MDBRow, MDBCol, MDBCard, MDBCardBody, MDBIcon } from "mdbreact";

import STC from '../../images/STC.jpg'
import THP from '../../images/THP.jpg'
import FP from '../../images/FP.png'
import EP from '../../images/EP.png'

const TeamPage = () => {
  return (
    <MDBCard className="my-5 px-5 pb-5 text-center noMargin">
        <MDBCardBody>
          <h2 className="h1-responsive font-weight-bold my-5 black-text">
            Charity
          </h2>
        
          <MDBRow>
            <MDBCol lg="3" md="6" className="mb-lg-0 mb-5">
              <img
                src={STC}
                className="rounded-circle z-depth-1 img-fluid charityLogo"
                alt="Sample avatar"
              />
              <h5 className="font-weight-bold mt-4 mb-3 black-text">Save the Children</h5>
              <p className="text-uppercase blue-text">The World’s Leading Expert on Childhood Education, Health and Protection</p>
              <p className="grey-text">
              As the coronavirus pandemic has spread to almost every country in the world, the poorest and most disadvantaged families are hurt the hardest. Save the Children is working around the clock to ensure that the health and well-being of children are protected. They work in over 100 countries, doing whatever it takes — every day and in times of crisis — to give children a healthy start in life, the opportunity to learn and protection from harm. In 2020 alone, they have reached more than 197 million children.
              </p>
              
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-lg-0 mb-5">
              <img
                src={THP}
                className="rounded-circle z-depth-1 img-fluid charityLogo"
                alt="Sample avatar"
              />
              <h5 className="font-weight-bold mt-4 mb-3 black-text">The Hunger Project</h5>
              <p className="text-uppercase blue-text">Ending Hunger Starts With People</p>
              <p className="grey-text">
              Their goal is to end hunger and poverty by pioneering sustainable, grassroots, women-centered strategies and advocating for their widespread adoption in countries throughout the world. The Hunger Project has a global reach in rural communities across Africa, South Asia and Latin America, encompassing millions of people working to end their own hunger and poverty.
              </p>
             
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-lg-0 mb-5">
              <img
                src={FP}
                className="rounded-circle z-depth-1 img-fluid charityLogo"
                alt="Sample avatar"
              />
              <h5 className="font-weight-bold mt-4 mb-3 black-text">FOUR PAWS</h5>
              <p className="text-uppercase blue-text">Help Us Create A Better World for Animals</p>
              <p className="grey-text">
              FOUR PAWS is the global animal welfare organization for animals under direct human influence, which reveals suffering, rescues animals in need and protects them. Sustainable campaigns and projects focus on companion animals including stray dogs and cats, farm animals and wild animals – such as bears, big cats, orangutans and elephants – kept in inappropriate conditions as well as in disaster and conflict zones.
              </p>
             
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-lg-0 mb-5">
              <img
                src={EP}
                className="rounded-circle z-depth-1 img-fluid charityLogo"
                alt="Sample avatar"
              />
              <h5 className="font-weight-bold mt-4 mb-3 black-text">Eve’s Place</h5>
              <p className="text-uppercase blue-text">Empowering Victims of Domestic, Sexual & Teen Dating Abuse</p>
              <p className="grey-text">
              Eve's Place has provided innovative and empowerment-based programs to victims of domestic, sexual and teen dating abuse. The Mobile Advocacy Program can provide all the services regularly available in a shelter without the bed for any victim of domestic abuse. Eve’s Place also offers a vast set of services to victims of sexual and teen dating abuse. They partner with community resources to provide local locations convenient and safe for the client and their needs and to increase the area of access for services

              </p>
              
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
  );
}


const CharitySection = ({lightBg, id, imgStart, topLine, lightText, headline, description, darkText, buttonLabel, alt, img, dark, primary} ) => {
    return (
        <>
            <InfoContainer id={"charity"} lightBg={true} >
                <TeamPage/>
            </InfoContainer>
        </>
    )
}

export default CharitySection;

