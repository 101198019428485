import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import _ from "lodash";
import { Button, Search } from "../ButtonElement";



import {
  InfoContainer,
  InfoWrapper,
  WarImage,
  WarButton,
  HeroBtnWrapper,
  ImgWrapper,
  Stats,
  TeamElement,
  TeamWrapper,
  TeamStats,
  TeamControls,
  Save,
  RaceSelect,
  Container, TokenNumber, ImgWrap, Img, PowerLevelBar, ImageWrappers, Selector, SaveWrapper, WarLink, TeamControlsWrapper, PrizePool
} from "./WarElements";
import warLogo from "../../images/War-LOGO.png";
import { width } from "@mui/system";
import { addWarParticipant, getWarParticipantDetails, getMultipleTokens, getMultipleTokensWizard, getMultipleTokensMount } from '../../api/index';


// imports for TEAMS modal (material ui)
import Modal from '@mui/material/Modal';
import MaterialButton from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


//test imports 
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';

import TeamCard from "../Teams/Teams/TeamCard"
import Teams from "../Teams/Teams"

import Guilds from "../Guilds/Guilds"

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


import MyTeam from "../Teams/MyTeam"
import MyGuild from '../Guilds/MyGuild'

import WarLeaderboard from "../WarLeaderboard"

import './WarSection.css';

import { connect } from "../../redux/blockchain/blockchainActions";
import { jsxOpeningElement } from "@babel/types";
import GuildChat from "../Guilds/Chat";

import HomeIcon from '@mui/icons-material/Home';


import {
  getMyCurrentGuild

} from "../../api";


const CURRENT_WAR_NUM=10;

const WarControls = (statObjInit) => {
  const [statObj, setStatObj] = useState(statObjInit);


  const [enrolled, setEnrolled] = useState("Not Enrolled");

  const [entered, setEntered] = useState(false);

  const data = useSelector((state) => state.data);
  const [expand, setExpand] = useState(true);

  const blockchain = useSelector((state) => state.blockchain);

  const { REACT_APP_FANTOM_KNIGHTS_BACKEND_URL } = process.env;

  async function enroll() {

    try {

      addWarParticipant({ walletAddress: data.account, raceBonus: statObj.raceVal, leadershipMode: getLeadership(statObj.rangeval), nfts: data.walletOfOwner, nftsWizards: data.walletOfOwnerWizard, nftsMounts: data.walletWithMount })
      if (enrolled == "Not Enrolled") {
        setEnrolled("Enrolled");
        let newObj = { ...statObj }
        newObj.warPowerLevel = statObj.warPowerLevel + getTotalPowerMax(statObj.teamPowerLevel);
        setStatObj({ ...newObj });
      } else {
        setEnrolled("Updated");
      }

    } catch (Exception) {

    }

  }


  function getTotalPowerMax(totalPowerLevel) {
    return parseInt((totalPowerLevel + statObj.pairBoost + statObj.boostVeteran + statObj.boostKing + statObj.boostDark + statObj.boostAngel + statObj.boostQueen + statObj.boostMount + statObj.boostLeadership + statObj.boostRace + statObj.boostSet + (statObj.guild ? statObj.teamPowerLevel.toFixed(2) * 0.05 : 0)).toFixed(2))
  }
  function getTotalPowerMin(totalPowerLevel) {
    return parseInt((totalPowerLevel + statObj.pairBoost + statObj.boostVeteran + statObj.boostKing + statObj.boostDark + statObj.boostAngel + statObj.boostQueen + statObj.boostMount - statObj.boostLeadership + statObj.boostRace + statObj.boostSet + (statObj.guild ? statObj.teamPowerLevel.toFixed(2) * 0.05 : 0)).toFixed(2))
  }
  function getOddsOfWinningMax() {
    if (enrolled.includes("Not Enrolled")) {
      let odd = statObj.warPowerLevel == 0 ? 100 : ((getTotalPowerMax(statObj.teamPowerLevel) / (getTotalPowerMax(statObj.teamPowerLevel) + statObj.warPowerLevel)) * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);
    } else {
      let odd = statObj.warPowerLevel == 0 ? 100 : ((getTotalPowerMax(statObj.teamPowerLevel) / statObj.warPowerLevel) * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);

    }
  }
  function getOddsOfWinningMin() {
    if (enrolled.includes("Not Enrolled")) {
      let odd = statObj.warPowerLevel == 0 ? 0 : (
        (getTotalPowerMin(statObj.teamPowerLevel)
          /
          (getTotalPowerMax(statObj.teamPowerLevel) + statObj.warPowerLevel))
        * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);

    } else {
      let odd = statObj.warPowerLevel == 0 ? 0 : ((getTotalPowerMin(statObj.teamPowerLevel) / statObj.warPowerLevel) * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);

    }
  }

  function getRaceBoost(race, numRace) {
    let raceMap = { "Elf": 4500, "Normal": 1000, "Shadow": 1500, "Leopard": 3000, "Burnt": 3000, "None": 0 }
    return raceMap[race] * numRace;
  }

  function getLeadershipBoost(leadershipMode) {

    switch (leadershipMode) {
      case "Calm":
        return 0;
        break;
      case "Anger":
        return 0.1;
        break;
      case "Rage":
        return 0.2
        break;
      case "Frenzy":
        return 0.3
        break;
    }
  }


  function getLeadershipColor(leadershipMode) {

    switch (leadershipMode) {
      case "Calm":
        return 'white';
        break;
      case "Anger":
        return '#f3c648';
        break;
      case "Rage":
        return '#ff6700'
        break;
      case "Frenzy":
        return 'red'
        break;
    }
    return 'white'
  }




  function handleLeadership(val) {
    let newStatObj = { ...statObj };

    newStatObj.rangeval = val;
    newStatObj.boostLeadership = getLeadershipBoost(getLeadership(newStatObj.rangeval)) * newStatObj.teamPowerLevel;
    setStatObj(newStatObj);

  }

  useEffect(() => {
    setStatObj(statObjInit);
    if (statObjInit.enrolled) setEnrolled("Enrolled");

  }, [statObjInit]);


  function getLeadership(num) {
    var leaderships = ['Calm', 'Anger', 'Rage', 'Frenzy'];
    return leaderships[num];
  }
  function getLeadershipNum(type) {
    var leaderships = ['Calm', 'Anger', 'Rage', 'Frenzy'];
    return leaderships.indexOf(type);
  }
  function handleRaceChange(e) {
    let newStatObj = { ...statObj };
    newStatObj.raceVal = e.target.value;
    newStatObj.boostRace = getRaceBoost(newStatObj.raceVal, newStatObj.raceCountMap[newStatObj.raceVal])
    setStatObj(newStatObj);
  }


  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [valueModal, setValueModal] = React.useState('1');
  //Teams modals
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Guilds modal open/close
  const [openGuild, setOpenGuild] = React.useState(false);
  const handleOpenGuild = () => setOpenGuild(true);
  const handleCloseGuild = () => setOpenGuild(false);

  const handleChange = (event, newValueModal) => {
    setValueModal(newValueModal);
  };


  function getRaceName(val) {
    if (val == "Burnt") {
      return "Burnt/Demon"
    } else if (val == "Leopard") {
      return "Leopard/Alien"

    } else {
      return val;
    }

  }
  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
    // width: '50%',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid',
    boxShadow: 24,
    p: 4,
    // width:"100%"
  };


  return (
    <>
      <TeamWrapper>
        <TeamControls>
          <h1 >War Controls</h1>
          <TeamControlsWrapper >
            <div style={{ width: window.innerWidth < 700 && "300px", paddingLeft: window.innerWidth < 700 && "20px" }}>
              <h4>Race Boost </h4>

              <RaceSelect value={statObj.raceVal} onChange={(e) => handleRaceChange(e)} >
                {statObj.raceValList.map(val =>
                  <option value={val} selected={val == statObj.raceVal}>{getRaceName(val)}</option>
                )}
              </RaceSelect>
            </div>
            <Selector style={{ paddingTop: window.innerWidth < 700 ? "15px" : "10px", paddingLeft: window.innerWidth < 700 && "20px" }}>
              <h4>Leadership Mode</h4>
              <input type="range" value={statObj.rangeval} className="custom-range" min="0" max="3"
                onChange={(event) => handleLeadership(event.target.value)} />
              <h5 style={{ color: getLeadershipColor(getLeadership(statObj.rangeval)) }}>{getLeadership(statObj.rangeval)}</h5>

            </Selector>
            <SaveWrapper style={{ paddingTop: window.innerWidth <700 ? "15px" : "10px" }} >
              <h4>Status: {enrolled}</h4>
              {/* <h4>Status: War Closed</h4> */}
              <Save onClick={(e) => enroll()}>Enroll</Save>
              {/* <Save disabled >Enroll</Save> */}
            </SaveWrapper>


            {enrolled !== "Not Enrolled" &&

              <>
                <SaveWrapper style={{ paddingTop: "45px" }}>

                  <Save onClick={(e) => handleOpen()}>Team Management</Save>


                </SaveWrapper>
                <SaveWrapper style={{ paddingTop: "45px" }}>


                  <Save onClick={(e) => handleOpenGuild()}>Guilds <sup style={{ color: "#c90101", fontSize: "12px" }}>new!</sup></Save>

                </SaveWrapper>
              </>

            }



            {/* <MaterialButton onClick={handleOpen} >TEAMS</MaterialButton> */}

          </TeamControlsWrapper>

        </TeamControls>

        <Stats enrolled={entered}>
          <table>
            <tr><th><div>Stats</div></th></tr>

            <tr><td>War Power Level: {numberWithCommas(statObj.warPowerLevel.toFixed(2))}</td></tr>
            <tr><td>Your Power Level: {numberWithCommas(getTotalPowerMin(statObj.teamPowerLevel))} {statObj.boostLeadership.toFixed(2) != 0 ? "- " + numberWithCommas(getTotalPowerMax(statObj.teamPowerLevel)) : ""} {expand ? <FaMinusSquare onClick={() => setExpand(!expand)} /> : <FaPlusSquare onClick={() => setExpand(!expand)} />} </td></tr>
            {expand && <>
              <tr><td class="expand"> Base Power Level: {numberWithCommas(statObj.teamPowerLevel.toFixed(2))}</td></tr>
              {statObj.boostRace.toFixed(2) != 0 && <tr><td class="expand"> Race Boost Level: {numberWithCommas(statObj.boostRace.toFixed(2))}</td></tr>}
              {statObj.boostSet.toFixed(2) != 0 && <tr><td class="expand"> Team Set Boost: {numberWithCommas(statObj.boostSet.toFixed(2))}</td></tr>}
              {statObj.boostLeadership.toFixed(2) != 0 && <tr><td class="expand" > Leadership Boost: +/-{numberWithCommas(statObj.boostLeadership.toFixed(2))}</td></tr>}
              {statObj.boostKing.toFixed(2) != 0 && <tr><td class="expand"> King Boost: {numberWithCommas(statObj.boostKing.toFixed(2))}</td></tr>}
              {statObj.boostQueen.toFixed(2) != 0 && <tr><td class="expand"> Queen Boost: {numberWithCommas(statObj.boostQueen.toFixed(2))}</td></tr>}
              {statObj.boostAngel.toFixed(2) != 0 && <tr><td class="expand"> Angel Boost: {numberWithCommas(statObj.boostAngel.toFixed(2))}</td></tr>}
              {statObj.boostDark.toFixed(2) != 0 && <tr><td class="expand"> Dark Wizard Boost: {numberWithCommas(statObj.boostDark.toFixed(2))}</td></tr>}
              {statObj.boostMount.toFixed(2) != 0 && <tr><td class="expand"> Mount Boost: {numberWithCommas(statObj.boostMount.toFixed(2))}</td></tr>}

              {statObj.guild ?
                <tr><td class="expand"> Guild Boost: {numberWithCommas((statObj.teamPowerLevel * 0.05).toFixed(2))}</td></tr> :
                <tr><td class="expand"> Guild Boost: 0</td></tr>
              }
              {statObj.boostVeteran.toFixed(2) != 0 && <tr><td class="expand"> War Veteran Boost: {numberWithCommas(statObj.boostVeteran.toFixed(2))}</td></tr>}
              {statObj.pairBoost.toFixed(2) != 0 && <tr><td class="expand"> Pair Boost: {numberWithCommas(statObj.pairBoost.toFixed(2))}</td></tr>}

            </>}
            <tr><td>Chance of winning: {getOddsOfWinningMin()}% {statObj.boostLeadership.toFixed(2) != 0 ? "- " + getOddsOfWinningMax() + "%" : ""} </td></tr>

          </table>
        </Stats>




      </TeamWrapper>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >



        <Box sx={style} className="modalSize">

          {/* <Box sx={{ width: '100%', typography: 'body1' }}> */}
          <TabContext value={valueModal} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example"  >
                <Tab label="My Team" value="1" style={{ color: "black", outline: "none" }} />
                <Tab label="Teams" value="2" style={{ color: "black", outline: "none" }} />

              </TabList>
            </Box>
            <TabPanel value="1" style={{ overflowY: "auto" }}><MyTeam></MyTeam></TabPanel>
            <TabPanel value="2" style={{ overflowY: "auto" }}><Teams></Teams></TabPanel>


          </TabContext>
        </Box>


        {/* </Box> */}


      </Modal>

      <Modal
        open={openGuild}
        onClose={handleCloseGuild}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >



        <Box sx={style} className="modalSize">

          {/* <Box sx={{ width: '100%', typography: 'body1' }}> */}
          <TabContext value={valueModal} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example"  >
                <Tab label="My Guild" value="1" style={{ color: "black", outline: "none" }} />
                <Tab label="Guilds" value="2" style={{ color: "black", outline: "none" }} />

              </TabList>
            </Box>
            {/* TODO: MyGuild here instead */}
            <TabPanel value="1" style={{ overflowY: "auto" }}><MyGuild></MyGuild></TabPanel>
            {/* TODO: Guilds here */}
            <TabPanel value="2" style={{ overflowY: "auto" }}><Guilds></Guilds></TabPanel>

          </TabContext>
        </Box>


        {/* </Box> */}


      </Modal>
    </>
  );

}

// ==================================================================================================================================
// ========================================================== WAR SECTION ===========================================================
// ==================================================================================================================================


const WarSection = () => {
  const [statObjGlobal, setStatObj] = useState({
    totalPowerLevel: 0,
    rangeval: 0,
    raceCountMap: {},
    raceValList: ['None'],
    boostKing: 0,
    boostRace: 0,
    boostQueen: 0,
    boostAngel: 0,
    boostMount: 0,
    boostDark: 0,
    boostLeadership: 0,
    boostSet: 0,
    teamPowerLevel: 0,
    warPowerLevel: 0,
    raceVal: 'None',
    enrolled: false,
    boostVeteran: 0,
    pairBoost: 0,

  });


  const [entered, setEntered] = useState(false);
  const [statusText, setStatus] = useState("");
  const [rangeval, setRangeval] = useState(0);
  const [warPowerLevel, setWarPowerLevel] = useState(0);
  const [teamPowerLevel, setTeamPowerLevel] = useState(0);
  const [boostSet, setBoostSet] = useState(0);
  const [boostKing, setBoostKing] = useState(0);
  const [boostLeadership, setBoostLeadership] = useState(0);
  const [boostRace, setBoostRace] = useState(0);
  const [prizePool, setPrizePool] = useState(0);
  const [oddsOfWinning, setOddsOfWinning] = useState(0);
  const [raceValList, setRaceValList] = useState(['None']);
  const [raceCountMap, setRaceCountMap] = useState({ 'None': 0 });
  const [raceVal, setRaceVal] = useState('None');
  const data = useSelector((state) => state.data);
  const [powerMap, setPowerMap] = useState({});
  const [powerMapWizard, setPowerMapWizard] = useState({});
  const [powerMapMount, setPowerMapMount] = useState({});
  const [raceMap, setRaceMap] = useState({});
  const [raceMapWizard, setRaceMapWizard] = useState({});
  const [expand, setExpand] = useState(true);

  const blockchain = useSelector((state) => state.blockchain);

  const { REACT_APP_FANTOM_KNIGHTS_BACKEND_URL } = process.env;

  const [warStats, setWarStats] = useState({});
 


  function getGlobalStats() {
    // console.log("getGlobalStats :: entered")
    fetch(REACT_APP_FANTOM_KNIGHTS_BACKEND_URL + "/war/getWarGlobalStats")
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(
        (result) => {

          setWarPowerLevel(result.totalPowerLevelWar);
          setPrizePool(result.prizePool);
        },

        (error) => {

        }
      )



  }
  function getRaceBoost(race, numRace) {
    let raceMap = { "Elf": 4500, "Normal": 1000, "Shadow": 1500, "Leopard": 3000, "Burnt": 3000, "None": 0 }
    return raceMap[race] * numRace;
  }


  function getSetBoost(numOfKnights) {
    if (numOfKnights >= 10) {
      return 0.3
    }
    if (numOfKnights >= 5) {
      return 0.15
    }
    if (numOfKnights >= 3) {
      return 0.1
    }
    return 0;
  }
  function getLeadershipBoost(leadershipMode) {

    switch (leadershipMode) {
      case "Calm":
        return 0;
        break;
      case "Anger":
        return 0.1;
        break;
      case "Rage":
        return 0.2
        break;
      case "Frenzy":
        return 0.3
        break;
    }
  }
  function getKingBoost(king) {
    return king ? 0.15 : 0;
  }

  function getAngelBoost(angel) { //#5
    return angel ? 0.05 : 0;
  }
  function getDarkBoost(dark) { //#4
    return dark ? 0.05 : 0;
  }
  function getQueenBoost(queen) { //#1
    return queen ? 0.15 : 0;
  }

  function getWarVeteranBoost(numWar) {

    return 0.015 * numWar;
  }

  async function getTeamDetails() {
    // console.log("getTeamDetails :: Entered + data.account = " + data.account )
    await getWarParticipantDetails({ walletAddress: data.account })
      .then(res => {
        if (res.status == 201) {
          // console.log(res)
          let statObj = JSON.parse(JSON.stringify(statObjGlobal));
          let teamData = res.data;

          statObj.rangeval = getLeadershipNum(teamData.leadershipMode);

          statObj.totalPowerLevel = 0;
          if (Object.values(powerMap).length != 0) {
            statObj.totalPowerLevel = Object.values(powerMap).reduce((sum, curr) => curr + sum);
          }
          if (Object.values(powerMapWizard).length != 0) {
            statObj.totalPowerLevel += Object.values(powerMapWizard).reduce((sum, curr) => curr + sum);
          }

          let racesAllowedMap = { 'None': 0 };

          let raceCountMap = _.countBy(_.values(raceMap).concat(_.values(raceMapWizard)));
          //merge raceMapWizard
          _.map(raceCountMap, (count, race) => {
            if (count >= 2) {
              racesAllowedMap[race] = count;
            }
          })

          let knightSortedPowerLevels = Object.values(powerMap).sort().reverse();
          let wizardSortedPowerLevels = Object.values(powerMapWizard).sort().reverse();
          let mountSortedPowerLevels = Object.values(powerMapMount).sort().reverse();

          let pairBoost = 0;

          knightSortedPowerLevels.forEach((pwrLevel, i) => {
            if (i < wizardSortedPowerLevels.length) {
              pairBoost += pwrLevel * 0.05;
              pairBoost += wizardSortedPowerLevels[i] * 0.05;
            }
          });
          statObj.boostMount = 0;

          for (var i = 0; i < Math.min(mountSortedPowerLevels.length, _.keys(powerMap).length + _.keys(powerMapWizard).length); i++) {
            statObj.boostMount += mountSortedPowerLevels[i];
          }
          statObj.boostLeadership = getLeadershipBoost(teamData.leadershipMode) * statObj.totalPowerLevel;
          statObj.raceVal = teamData.raceBonus;
          statObj.raceCountMap = racesAllowedMap;
          statObj.raceValList = _.uniq(['None'].concat(_.keys(racesAllowedMap)))
          statObj.boostKing = getKingBoost(data.walletOfOwner.includes("1")) * statObj.totalPowerLevel;
          statObj.boostQueen = getQueenBoost(data.walletOfOwnerWizard.includes("1")) * statObj.totalPowerLevel;
          statObj.boostDark = getDarkBoost(data.walletOfOwnerWizard.includes("4")) * statObj.totalPowerLevel;
          statObj.boostAngel = getAngelBoost(data.walletOfOwnerWizard.includes("5")) * statObj.totalPowerLevel;
          statObj.boostRace = getRaceBoost(teamData.raceBonus, racesAllowedMap[teamData.raceBonus])
          statObj.boostSet = getSetBoost(_.keys(powerMap).length + _.keys(powerMapWizard).length) * statObj.totalPowerLevel;
          statObj.teamPowerLevel = statObj.totalPowerLevel;
          statObj.warPowerLevel = warPowerLevel;
          localStorage.setItem("chatToken", teamData.token);
          statObj.enrolled = teamData.enrolled;
          statObj.guild = teamData.guild;
          statObj.boostVeteran = getWarVeteranBoost(teamData.numWar) * statObj.totalPowerLevel;
          statObj.pairBoost = pairBoost;
          setStatObj(statObj);

          if(teamData.warResults){
            let currWarResult = teamData.warResults.find(result=>result.warNumber==CURRENT_WAR_NUM);
            if(currWarResult) setWarStats(currWarResult);
          }


        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(err => {
        // console.log("getTeamDetails :: ERROR")
        if (err.response.data.message == 'User has not signed up for war') {
          // console.log("User has not signed up for war")

          let statObj = JSON.parse(JSON.stringify({}));
          // console.log(" hey " +  JSON.stringify(statObj))

          statObj.totalPowerLevel = 0;
          statObj.rangeval = 0;
          // console.log("nfts owned + " + JSON.stringify(powerMap))
          if (Object.values(powerMap).length != 0) {
            statObj.totalPowerLevel = Object.values(powerMap).reduce((sum, curr) => curr + sum);
          }
          if (Object.values(powerMapWizard).length != 0) {
            statObj.totalPowerLevel += Object.values(powerMapWizard).reduce((sum, curr) => curr + sum);
          }

          let knightSortedPowerLevels = Object.values(powerMap).sort().reverse();
          let wizardSortedPowerLevels = Object.values(powerMapWizard).sort().reverse();
          let mountSortedPowerLevels = Object.values(powerMapMount).sort().reverse();

          let pairBoost = 0;

          knightSortedPowerLevels.forEach((pwrLevel, i) => {
            if (i < wizardSortedPowerLevels.length) {
              pairBoost += pwrLevel * 0.05;
              pairBoost += wizardSortedPowerLevels[i] * 0.05;
            }
          });

          let racesAllowedMap = { 'None': 0 };

          let raceCountMap = _.countBy(_.values(raceMap).concat(_.values(raceMapWizard)));
          //merge raceMapWizard

          _.map(raceCountMap, (count, race) => {
            if (count >= 2) {
              racesAllowedMap[race] = count;
            }
          })
          statObj.boostMount = 0;

          for (var i = 0; i < Math.min(mountSortedPowerLevels.length, _.keys(powerMap).length + _.keys(powerMapWizard).length); i++) {
            statObj.boostMount += mountSortedPowerLevels[i];
          }
          statObj.raceVal = 'None';
          statObj.raceCountMap = racesAllowedMap;
          statObj.raceValList = _.uniq(['None'].concat(_.keys(racesAllowedMap)))
          statObj.boostKing = getKingBoost(data.walletOfOwner.includes("1")) * statObj.totalPowerLevel;
          statObj.boostQueen = getQueenBoost(data.walletOfOwnerWizard.includes("1")) * statObj.totalPowerLevel;
          statObj.boostDark = getDarkBoost(data.walletOfOwnerWizard.includes("4")) * statObj.totalPowerLevel;
          statObj.boostAngel = getAngelBoost(data.walletOfOwnerWizard.includes("5")) * statObj.totalPowerLevel;
          statObj.boostRace = getRaceBoost('None', racesAllowedMap['None'])
          statObj.boostLeadership = getLeadershipBoost(getLeadership(0)) * statObj.totalPowerLevel;
          statObj.boostSet = getSetBoost(_.keys(powerMap).length + _.keys(powerMapWizard).length) * statObj.totalPowerLevel;
          statObj.teamPowerLevel = statObj.totalPowerLevel;
          statObj.warPowerLevel = warPowerLevel;
          statObj.boostVeteran = 0;
          statObj.pairBoost = pairBoost;
          // console.log(statObj)

          setStatObj(statObj);

        }

      })

  }
  useEffect(() => {
    // console.log("use effect for get global states etc")
    // window.location.reload()
    getGlobalStats();

    if (data.account) {
      // console.log("data.account = " + data.walletOfOwner)
      getAllTokens(data.walletOfOwner);
      getAllTokensWizard(data.walletOfOwnerWizard);
      getAllTokensMounts(data.walletWithMount);
      getTeamDetails();
      setEntered(false);
      setStatus("")

    }
  }, [data.account]);

  async function enter() {

    const message = "Enter War Management"
    // let signature = await blockchain.web3.eth.sign(blockchain.web3.utils.utf8ToHex(message),blockchain.account)
    //0xAfC576C64586CCc796Bd1be03bAdB45C89349342
    let signature = await blockchain.web3.eth.personal.sign(blockchain.web3.utils.utf8ToHex(message), blockchain.account, "")


    blockchain.web3.eth.personal.ecRecover(blockchain.web3.utils.utf8ToHex(message), signature, function (error, signing_address) {
      // if signing address == the address (CORRECT))
      if (signing_address == blockchain.account) {
        // Send enroll call to backend, with header of the access token

        if (data.account) {
          if (data.walletOfOwner.length > 0 || data.walletOfOwnerWizard.length > 0) {
            setEntered(true);
            getTeamDetails();
            getMyGuild();
          } else {
            setStatus("Only Fantom Knight/Wizard holders can enter!")

          }
        } else {
          setStatus("Connect wallet first!")
        }

      } else { // fake address (INCORRECT)

        // setStatus("Only Fantom Knight holders can enter!")

      }
    })





  }


  function getLeadership(num) {
    var leaderships = ['Calm', 'Anger', 'Rage', 'Frenzy'];
    return leaderships[num];
  }
  function getLeadershipNum(type) {
    var leaderships = ['Calm', 'Anger', 'Rage', 'Frenzy'];
    return leaderships.indexOf(type);
  }




  async function getAllTokens(idsToFetch) {
    await getMultipleTokens(idsToFetch)
      .then(res => {

        if (res.status == 200) {

          let tempPowerMap = {};
          let tempRaceMap = {};

          for (var curr of res.data.results) {
            tempRaceMap[parseInt(curr.id)] = curr.race
            tempPowerMap[parseInt(curr.id)] = parseInt(curr.powerLevel);
          }
          setPowerMap(tempPowerMap);
          setRaceMap(tempRaceMap);

        } else {
          throw new Error('Something went wrong');
        }
      });
  }

  async function getAllTokensMounts(idsToFetch) {

    //walletOfOwnerMounts
    getMultipleTokensMount(idsToFetch)
      .then(res => {

        if (res.status == 200) {
          let powerMapTemp = {};

          // console.log(res.data.results)

          for (var curr of res.data.results) {
            powerMapTemp[parseInt(curr.id)] = parseInt(curr.powerLevel);
          }
          const newpower = JSON.stringify(powerMapTemp)
          setPowerMapMount(JSON.parse(newpower))

        } else {
          throw new Error('Something went wrong');
        }
      });
  }

  async function getAllTokensWizard(idsToFetch) {
    await getMultipleTokensWizard(idsToFetch)
      .then(res => {

        if (res.status == 200) {

          let tempPowerMap = {};
          let tempRaceMap = {};

          for (var curr of res.data.results) {
            tempRaceMap[parseInt(curr.id)] = curr.race
            tempPowerMap[parseInt(curr.id)] = parseInt(curr.powerLevel);
          }
          setPowerMapWizard(tempPowerMap);
          setRaceMapWizard(tempRaceMap);

        } else {
          throw new Error('Something went wrong');
        }
      });
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [open, setOpen] = useState(false);

  async function handleOpen() {
    // console.log("handleOpen() ")
    // console.log(statObjGlobal)
    setOpen(true);
  }


  function handleClose() {
    setOpen(false);
  }


  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
    // width: '50%',
    // height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid',
    boxShadow: 24,
    p: 4,
    // width:"100%"
  };

  const [valueModal, setValueModal] = React.useState('1');


  const handleChange = (event, newValueModal) => {
    setValueModal(newValueModal);
  };

  function getTotalPowerMax(totalPowerLevel) {
    return parseInt((totalPowerLevel + statObjGlobal.pairBoost + statObjGlobal.boostVeteran + statObjGlobal.boostKing + statObjGlobal.boostDark + statObjGlobal.boostAngel + statObjGlobal.boostQueen + statObjGlobal.boostMount + statObjGlobal.boostLeadership + statObjGlobal.boostRace + statObjGlobal.boostSet + (statObjGlobal.guild ? statObjGlobal.teamPowerLevel.toFixed(2) * 0.05 : 0)).toFixed(2))
  }
  function getTotalPowerMin(totalPowerLevel) {
    return parseInt((totalPowerLevel + statObjGlobal.pairBoost + statObjGlobal.boostVeteran + statObjGlobal.boostKing + statObjGlobal.boostDark + statObjGlobal.boostAngel + statObjGlobal.boostQueen + statObjGlobal.boostMount - statObjGlobal.boostLeadership + statObjGlobal.boostRace + statObjGlobal.boostSet + (statObjGlobal.guild ? statObjGlobal.teamPowerLevel.toFixed(2) * 0.05 : 0)).toFixed(2))
  }
  function getOddsOfWinningMax() {
    if (!statObjGlobal.enrolled) {
      let odd = statObjGlobal.warPowerLevel == 0 ? 100 : ((getTotalPowerMax(statObjGlobal.teamPowerLevel) / (getTotalPowerMax(statObjGlobal.teamPowerLevel) + statObjGlobal.warPowerLevel)) * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);
    } else {
      let odd = statObjGlobal.warPowerLevel == 0 ? 100 : ((getTotalPowerMax(statObjGlobal.teamPowerLevel) / statObjGlobal.warPowerLevel) * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);

    }
  }
  function getOddsOfWinningMin() {
    if (!statObjGlobal.enrolled) {
      let odd = statObjGlobal.warPowerLevel == 0 ? 0 : (
        (getTotalPowerMin(statObjGlobal.teamPowerLevel)
          /
          (getTotalPowerMax(statObjGlobal.teamPowerLevel) + statObjGlobal.warPowerLevel))
        * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);

    } else {
      let odd = statObjGlobal.warPowerLevel == 0 ? 0 : ((getTotalPowerMin(statObjGlobal.teamPowerLevel) / statObjGlobal.warPowerLevel) * 100);
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);

    }
  }

  function getTeamOddsOfWinning(){
    if(warStats.team){
      let odd = warStats.team.powerLevel/statObjGlobal.warPowerLevel *100;
      return ((1 - (Math.pow((100 - odd) / 100, 5))) * 100).toFixed(4);
    }
  }

  //  Added for show what guild youre a part of 

  const [myGuild, setMyGuild] = useState(null);



  async function getMyGuild() {
    // console.log("getMyGuild()" + data.account)
    try {
      let response = await getMyCurrentGuild(data.account);
      if (response.status == 201) {
        let guild = response.data;
        setMyGuild(guild)
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      setMyGuild(null);

      // console.log(error);
    }
  }

  return (
    <>
      <InfoContainer id={"war"} lightBg={false}>
        <InfoWrapper enrolled={true}>
          <ImgWrapper>

            <WarImage enrolled={entered} src={warLogo} />
            {/* <Stats enrolled={enrolled}>
              <table>
                <tr><th>Stats</th></tr>
                <tr><td>Total Knights Fighting: 9999</td></tr>
                <tr><td>Total Power Level: 200,000,000</td></tr>
                <tr><td>Total Teams Enrolled: 450</td></tr>
         
              </table>
            </Stats> */}
          </ImgWrapper>


       

          

          {/*------------------------------ When WAR has ended------------------------------*/}

    

          {/* ---------------------------When WAR is on going ------------------------------*/}

          {/* <div style={{ width: "100%", textAlign: "center", padding: '15px' }}>
            <PrizePool  > <div >PRIZE POOL</div> ${numberWithCommas(prizePool)} FTM</PrizePool>
          </div> 

           {!entered && <>
            <HeroBtnWrapper>
              {" "}
              {data.account ?
                <WarButton onClick={() => { enter(); }} > Enter </WarButton>
                :

                <>
                  <WarLink to='home' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                    Connect Wallet
                  </WarLink>


                </>


              }

              <p>{statusText}</p>

            </HeroBtnWrapper>

          </>
          }


          {entered && <>

            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              <Grid item xs={12}>

              </Grid>
            </Grid>
            <WarControls {...statObjGlobal} />


            <ImageWrappers>
              {data.walletOfOwner.map(tokenId =>

                <Container>
                  <TokenNumber>Fantom Knight {tokenId}</TokenNumber>
                  <ImgWrap fillerNeeded={false}>
                    <Img src={'https://fantomknights.art/images/' + tokenId} alt={'knight'} />
                  </ImgWrap>
                  <PowerLevelBar>Power Level: {powerMap[tokenId]}
                  </PowerLevelBar>

                </Container>
              )}
              {data.walletOfOwnerWizard.map(tokenId =>

                <Container>
                  <TokenNumber>Fantom Wizard {tokenId}</TokenNumber>
                  <ImgWrap fillerNeeded={false}>
                    <Img src={REACT_APP_FANTOM_KNIGHTS_BACKEND_URL + "/wizards/images/" + tokenId} alt={'wizard'} />
                  </ImgWrap>
                  <PowerLevelBar>Power Level: {powerMapWizard[tokenId]}
                  </PowerLevelBar>

                </Container>
              )}
              {data.walletWithMount.map(tokenId =>

                <Container>
                  <TokenNumber>Fantom Mount {tokenId}</TokenNumber>
                  <ImgWrap fillerNeeded={false}>
                    <Img src={REACT_APP_FANTOM_KNIGHTS_BACKEND_URL + "/mounts/images/" + tokenId} alt={'mount'} />
                  </ImgWrap>
                  <PowerLevelBar>Power Level: {powerMapMount[tokenId]}
                  </PowerLevelBar>

                </Container>
              )}
            </ImageWrappers>
          </>
          }  */}


        </InfoWrapper>
      </InfoContainer>



    </>
  );
};

export default WarSection;
