import { useDispatch, useSelector } from "react-redux";
// extracting actions from the respective actioners 
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";

import React, { useState, useRef, useEffect } from 'react';
import {
  Button, QTY,
  Mint
} from '../ButtonElement';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  NFTCycle,
  MintRemaining,
  RareMountToggle,

} from './HeroElements';
import nft from '../../images/nft.gif'

import bg from '../../images/bg.png'
import bg2 from '../../images/bg2.png'
import wizards from "../../images/wizards.mp4"
import wizardsNew from "../../images/newWizards.mp4"
import wizardsSmall from "../../images/newWizardsSmall.mp4"


import { FaQuestionCircle, FaRegQuestionCircle, FaQuestion } from 'react-icons/fa';

import Tooltip from '@mui/material/Tooltip';
import { get } from "react-scroll/modules/mixins/scroller";

import { addWizardMint, fetchAccessToken } from '../../api/index'

// import Switch from '@mui/material/Switch';

// imports for rare mount toggle switch
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ReactTooltip from 'react-tooltip';


const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const [connected, setConnected] = useState(false);






  const onHover = () => {
    setHover(!hover);
  }
  const handleConnect = () => {
    // console.log(connected)
    setConnected(!connected);
  }

  const getConnected = () => {
    return this.state.connected;
  }

  const dispatch = useDispatch();

  //extracting reducers
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);

  const [processStatus, setProcessStatus] = useState("");

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };


  const [switchState, setSwitchState] = useState(false);

  const handleChange = (event) => {
    setSwitchState(event.target.checked);
  };

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }

    // if rare mounts boost enabled --> increased cost to 125ftm 
    let costOfMint = data.cost
    if (switchState) {

      //changeback
      // costOfMint+=26000000000000000000
      costOfMint += 11000000000000000000

      // costOfMint+=100000000000000000
    }

    setFeedback("Minting your NFT...");
    setClaimingNft(true);
    setProcessStatus("Processing")

    // smartContract
    // console.log("data.cost = " + (data.cost/1e18) );
    // console.log("from: blockchain.account = "  +blockchain.account)
    blockchain.smartContractWizards.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: (285000 * _amount).toString(),
        // gasLimit: "850000",
        // to: "0x827acb09a2dc20e39c9aad7f7190d9bc53534192",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((((costOfMint) / 1e18) * _amount).toString(), "ether"),
      })
      // .catch(error =>{
      //   console.log("PROMISE ERROR CAUGHT !!!! ~~~~~~~~~~~~~~~ ")
      //   console.log("ONCE ERROR")
      //   console.log(error);
      //   setFeedback("Sorry, something went wrong please try again later.");
      //   setClaimingNft(false);
      // })
      .once("error", (err) => {
        // console.log("ONCE ERROR")
        // console.log(err.message);
        // setFeedback("Sorry, something went wrong please try again later.");
        setFeedback("Try again...")
        setClaimingNft(false);
      }).catch(error => {
        setClaimingNft(false);
        // console.log("PROMISE ERROR CAUGHT !!!! ~~~~~~~~~~~~~~~ ")
      })
      .then((receipt) => {
        // console.log(receipt);

        setFeedback(
          "Success!"
        );
        setClaimingNft(false);
        // console.log("dispatching fetchData")
        dispatch(fetchData(blockchain.account));

        // TODO: save it in backend here!
        if (receipt && switchState) addWizardMint(blockchain.account, _amount);
      });
  };


  const getData = () => {
    // console.log("getData :: ENTERED")
    // console.log("blockchain.account = " + blockchain.account )
    // console.log( " blockchain.smartContractWizards ")
    // console.log(blockchain.smartContractWizards)

    if (blockchain.account !== "" && blockchain.smartContractWizards !== null) {
      // console.log( " blockchain.account !== '' && blockchain.smartContractWizards !== null ")
      dispatch(fetchData(blockchain.account));
      getAccessToken(blockchain.account)

    }
  };

  const getAccessToken = () => {

    if (blockchain.account !== "" && blockchain.smartContractWizards !== null) {
      fetchAccessToken(blockchain.account)
        // fetchAccessToken("0x1c6c476117fc7cd39ee7b9fc6e76946d2cf39bc7")
        // fetchAccessToken("0x3e590fbb993bf1e97f04fe615c64bfa37dd84999")
        // fetchAccessToken("0x1de82f878e6aa9c82a93f1956893281581a8fbc0")
        .then(data => {
          localStorage.setItem("accessToken", data.data.accessToken)
        })

    }
  }


  useEffect(() => {
    getData();
  }, [blockchain.account]);


  const { REACT_APP_FANTOM_KNIGHTS_MAX_SUPPLY, REACT_APP_FANTOM_NETWORK_ID } = process.env;




  // ========================================== COUNT DOWN CLOCK COMPONENT START ==================================
  // function component
  const AnimatedCard = ({ animation, digit }) => {
    return (
      <div className={`flipCard ${animation}`}>
        <span>{digit}</span>
      </div>
    );
  };

  // function component
  const StaticCard = ({ position, digit }) => {
    return (
      <div className={position}>
        <span>{digit}</span>
      </div>
    );
  };

  // function component
  const FlipUnitContainer = ({ digit, shuffle, unit }) => {

    // assign digit values
    let currentDigit = digit;
    let previousDigit = digit + 1;

    // to prevent a negative value

    if (unit == 'days') {
      previousDigit = previousDigit === -1
        ? 31
        : previousDigit;
    } else {
      if (unit !== 'hours') {
        previousDigit = previousDigit === -1
          ? 59
          : previousDigit;
      } else {
        previousDigit = previousDigit === -1
          ? 23
          : previousDigit;
      }
    }

    // add zero
    if (currentDigit < 10) {
      currentDigit = `0${currentDigit}`;
    }
    if (previousDigit < 10) {
      previousDigit = `0${previousDigit}`;
    }

    // shuffle digits
    const digit1 = shuffle
      ? previousDigit
      : currentDigit;
    const digit2 = !shuffle
      ? previousDigit
      : currentDigit;

    // shuffle animations
    const animation1 = shuffle
      ? 'fold'
      : 'unfold';
    const animation2 = !shuffle
      ? 'fold'
      : 'unfold';
    let type = unit;
    switch (unit) {
      case 'hours':
        type = 'Hour';
        break;
      case 'minutes':
        type = 'Minute';
        break;
      case 'seconds':
        type = 'Seconds';
        break;
      case 'days':
        type = 'Days';
        break;
    }

    return (
      <div className={'flipUnitContainer'}>
        <StaticCard
          position={'upperCard'}
          digit={currentDigit}
        />
        <StaticCard
          position={'lowerCard'}
          digit={previousDigit}
        />
        <AnimatedCard
          digit={digit1}
          animation={animation1}
        />
        <AnimatedCard
          digit={digit2}
          animation={animation2}
        />
        <p class="typeOfTime">{type}</p>
      </div>
    );
  };

  // class component
  class FlipClock extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        days: 0,
        daysShuffle: true,
        hours: 0,
        hoursShuffle: true,
        minutes: 0,
        minutesShuffle: true,
        seconds: 0,
        secondsShuffle: true
      };
    }

    componentDidMount() {
      this.timerID = setInterval(
        () => this.updateTime(),
        50
      );
    }

    componentWillUnmount() {
      clearInterval(this.timerID);
    }

    updateTime() {
      // changeback
      // const countdownDate = new Date(Date.UTC(2021, 11, 26, 17, 0, 0));
      const countdownDate = new Date(Date.UTC(2021, 11, 25, 2, 0, 0));
      // console.log(countdownDate)
      // const countdownDate = new Date(Date.UTC(2021, 9, 29, 19,10,0));    




      const now = new Date();
      const nowUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));


      const distance = countdownDate - nowUTC;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(this.timerID);
        setCountdownEnded(true);
      }



      if (days !== this.state.days) {
        const daysShuffle = !this.state.daysShuffle;
        this.setState({
          days,
          daysShuffle
        });
      }
      if (hours !== this.state.hours) {
        const hoursShuffle = !this.state.hoursShuffle;
        this.setState({
          hours,
          hoursShuffle
        });
      }
      // on minute chanage, update minutes and shuffle state
      if (minutes !== this.state.minutes) {
        const minutesShuffle = !this.state.minutesShuffle;
        this.setState({
          minutes,
          minutesShuffle
        });
      }
      // on second chanage, update seconds and shuffle state
      if (seconds !== this.state.seconds) {
        const secondsShuffle = !this.state.secondsShuffle;
        this.setState({
          seconds,
          secondsShuffle
        });
      }
    }

    render() {

      // state object destructuring
      const {
        days,
        hours,
        minutes,
        seconds,
        hoursShuffle,
        minutesShuffle,
        secondsShuffle,
        daysShuffle
      } = this.state;

      return (
        <div className={'flipClock'}>
          <FlipUnitContainer
            unit={'days'}
            digit={days}
            shuffle={daysShuffle}
          />

          <FlipUnitContainer
            unit={'hours'}
            digit={hours}
            shuffle={hoursShuffle}
          />

          <FlipUnitContainer
            unit={'minutes'}
            digit={minutes}
            shuffle={minutesShuffle}
          />
          <FlipUnitContainer
            unit={'seconds'}
            digit={seconds}
            shuffle={secondsShuffle}
          />
        </div>
      );
    }
  }

  // ========================================== COUNT DOWN CLOCK COMPONENT END ==================================

  let test = 'Guarantee yourself at least a RARE Rhino (30k Power Level) with your Wizard & <br /> 2x increased chances for Epic/Legendary/Mythic otherwise. You will not receive a common ever! [Additional 25 FTM]'
  return (
    <>
      <HeroContainer id='home'>
        <HeroBg>
          {/* <VideoBg  src={wizardsGif}/> */}
          {/* wizardsSmall */}
          {window.innerWidth > 450 ?
            (<video className='videoTag' autoPlay loop muted
              style={{
                width: "100%",
                height: "100%",
                "-o-object-fit": "cover",
                "object-fit": "cover",
                background: "#232a34"


              }}
            >

              <source src={wizardsNew} type='video/mp4'
              />
            </video>) :

            (<VideoBg  src={bg2}/>)

          }
        </HeroBg>
        <HeroContent style={{}}>

          {/* <HeroP>1
            <NFTCycle src={nft} />
          </HeroP> */}
          {/* <div style={{color:"white", 
          fontSize:window.innerWidth > 400 ? "25px":"22px", 
          "-webkit-text-stroke":"2px black", textAlign:"center", paddingBottom:"10px"}}>

            <span style={{color:"white", "-webkit-text-stroke":"2px black"}}>MINT FLASH SALE </span>
            <span style={{color:"#D3D3D3"}} className="strikethrough" >99</span>             
            <span style={{color:"red", 
            fontSize:window.innerWidth > 400 ? "29px":"25px", }}> 66 FTM!</span>
            
          </div> */}
          {countdownEnded && <>
            {/* <HeroBtnWrapper  style={{border:"1px solid yellow", marginBottom:"10px"}}> */}
            {/* <Switch {...label} defaultChecked /> */}
            {/* width:window.innerWidth<1200?"50%":"100%" */}
            {/* {blockchain.account !== "" && blockchain.smartContractWizards !== null && <RareMountToggle style={{ height: "120px", }}>

              
              <FormControl component="fieldset" variant="standard"
                style={{
                  borderLeft: "1px solid white",
                  borderBottom: "1px solid white",
                  borderRight: "1px solid white",
                  marginBottom: "5px",
                  background: "rgba(0,0,0,.4)",
                  marginLeft: window.innerWidth<450 && "8vw"


                }}>

                <FormLabel component="legend"
                  style={{
                    color: "white",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontFamily: "cartoon",
                    paddingLeft: "30px",
                    paddingRight: "5px",
                    paddingTop: "1px",
                    margin: "0px",
                    

                  }}> <span style={{color:"#FCA311"}}>4x</span> Rare Mount Chance!</FormLabel>
                <FormGroup>



                  <FormControlLabel
                    style={{
                      color: "white",
                      fontFamily: "cartoon",
                      paddingLeft: "20px",
                      fontWeight: "bold"

                    }}
                    control={
                      <Switch checked={switchState} color="warning" style={{ color: "#FCA311" }} onChange={handleChange} name="gilad" />
                    }


                    label={<div>Mount Rarity Boost</div>}
                  />

                  <FormControlLabel
                    style={{
                      color: "white",
                      fontFamily: "cartoon",
                      paddingLeft: "30px",
                      paddingBottom: "0px",
                      marginBottom: "0px"


                    }}
                    control={
                      <>
                        <h6>Please read the following </h6>
                        <div data-tip="
                        <br /> 4x increased chance for Rhino/War Elephant/Dragon/Phoenix [300k Power Level]
                        <br /> 4x increased chance for mount airdrop If you do not hold a Knight
                        <br />[Costs an additional 10 FTM]" data-for='path'><ErrorOutlineIcon /></div>
                        <ReactTooltip className="mountToolTip" id='path' multiline={true} />
                      </>
                    }
                    label=""
                  />



                </FormGroup>

              </FormControl>

            </RareMountToggle>} */}
            {/* </HeroBtnWrapper> */}

            <HeroBtnWrapper  >

              {blockchain.account === "" || blockchain.smartContractWizards === null ? (

                <Button
                  onClick={(e) => {
                    // console.log("connect button clicked")
                    e.preventDefault();
                    //TODO :: ADD BACK !!
                    dispatch(connect()).then(() => {
                      getData();
                      getAccessToken();
                    });
                    //TODO :: ADD BACK !!


                  }} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                  Connect Wallet {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>

              ) :

                (
                  // <>
                  //   <Mint onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80} disabled={data.cost == 0}
                  //     onClick={(e) => {
                  //       // console.log("Minting activated ");
                  //       // if(!claimingNft){
                  //       // e.preventDefault();
                  //       //TODO :: ADD BACK !!
                  //       claimNFTs(1);
                  //       //TODO :: ADD BACK !!
                  //       getData();
                  //       // }
                  //     }}>Mint</Mint>
                  //   <QTY onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80} disabled={data.cost == 0}
                  //     onClick={(e) => {
                  //       // console.log("Minting activated ");
                  //       // if(!claimingNft){
                  //       e.preventDefault();
                  //       //TODO :: ADD BACK !!
                  //       claimNFTs(3);
                  //       //TODO :: ADD BACK !!
                  //       getData();
                  //       // }
                  //     }}>3x</QTY>
                  //   <QTY onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80} disabled={data.cost == 0}
                  //     onClick={(e) => {
                  //       // console.log("Minting activated ");
                  //       // if(!claimingNft){
                  //       e.preventDefault();
                  //       //TODO :: ADD BACK !!
                  //       claimNFTs(5);
                  //       //TODO :: ADD BACK !!
                  //       getData();
                  //       // }
                  //     }}>5x</QTY>
                  //   <QTY onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80} disabled={data.cost == 0}
                  //     onClick={(e) => {
                  //       // console.log("Minting activated ");
                  //       // if(!claimingNft){
                  //       e.preventDefault();
                  //       //TODO :: ADD BACK !!
                  //       claimNFTs(10);
                  //       //TODO :: ADD BACK !!
                  //       getData();
                  //       // }
                  //     }}>10x</QTY>
                  // </>

                  <Button disabled onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                      Sold Out!
                  </Button>

                )
                // (

                //   <>
                //     <Button disabled onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                //       Coming soon...
                //     </Button>
                //   </>
                // )

              }
            </HeroBtnWrapper>

            {/* {(data.totalSupply == 0 || data.maxSupply == 0) ?
              (<MintRemaining> ?/{REACT_APP_FANTOM_KNIGHTS_MAX_SUPPLY} <div style={{ color: "red" }}>{blockchain.errorMsg}</div></MintRemaining>) :
              (<MintRemaining>  <div> {data.totalSupply}/{data.maxSupply}  Minted  </div>
                {claimingNft ? <div class="loading">{processStatus}</div> :
                  <div style={{}}>{feedback}</div>}

              </MintRemaining>)

            } */}
          </>}

          {/* <div style={{color:"white", paddingTop:"20px", paddingLeft:"10px",paddingRight:"10px", background: "rgba(0,0,0,.5)",}}>
                <h3 style={{color:"#FCA311"}}>Daily Bounty <span style={{color:"red"}}>[<span style={{fontSize:"20px"}}>Free NFT</span>]</span></h3>
                <h5 style={{color:"white", textAlign:"center"}}> Blue Raven</h5>
              </div> */}

          {/* <div style={{
            color: "white", paddingTop: "20px", paddingLeft: "10px", paddingRight: "10px", background: "rgba(0,0,0,.8)", border: "1px solid white",
            marginTop:"20px"

          }}>
            <h3 style={{ color: "#FCA311" }}>Daily Bounties <span style={{ color: "red" }}>[<span style={{ fontSize: "20px" }}>Free Wizards</span>]</span></h3> */}
            {/* <Tooltip title="First person to find and mint this attribute of the day, wins a Free Wizard! " placement="right"  >
              <h6 style={{ color: "white", textAlign: "left", display: "inline-block" }}>  1. Trait: Blue Raven <FaQuestionCircle /> </h6></Tooltip> */}
            {/* <>
              <div data-tip="First person to find and mint this attribute of the day, wins a Free Wizard!" data-for='path'> 1. Trait: Red Spirit Eyes <FaQuestionCircle /></div>
              <ReactTooltip className="mountToolTip" id='path' multiline={true} />
            </> */}
            {/* <Tooltip title="One random person to mint within every 25 mints receives a free Wizard! [1 winner every 25 mints] " placement="right"  ><h6 style={{ color: "white", textAlign: "left" }}> 2. Random winner every 25 mints <FaQuestionCircle /></h6></Tooltip> */}

            {/* <>
              <div data-tip="One random person to mint within every 25 mints receives a free Wizard! [1 winner every 25 mints]" data-for='path'>  2. Random winner every 25 mints <FaQuestionCircle /></div>
              <ReactTooltip className="mountToolTip" id='path' multiline={true} />
            </> */}

            {/* <Tooltip title="Every 100 Mints, the person to mint the lowest & highest power levels will receive a free Wizard [2 winners]" placement="right" ><h6 style={{ color: "white", textAlign: "left" }}> 3. Lowest & Highest Power levels <FaQuestionCircle /></h6></Tooltip>
            <h6 style={{ paddingTop: "10px" }}>It is possible to win all 3 in 1 mint!</h6> */}
{/* 
            <>
              <div data-tip="Every 100 Mints, the person to mint the lowest & highest power levels will receive a free Wizard [2 winners]" data-for='path'> 3. Lowest & Highest Power levels <FaQuestionCircle /></div>
              <ReactTooltip className="mountToolTip" id='path' multiline={true} />
            </>

            <h6 style={{ paddingTop: "15px" }}>It is possible to <span style={{color:"#FCA311"}}>WIN ALL 3</span> in 1 mint!</h6>

          </div> */}
          {
            !countdownEnded &&
            <div style={{ paddingBottom: "200px" }}>
              < FlipClock />
              <HeroBtnWrapper style={{ justifyContent: "center" }} >

                {blockchain.account === "" || blockchain.smartContractWizards === null ? (

                  <Button
                    onClick={(e) => {
                      // console.log("connect button clicked")
                      e.preventDefault();
                      //TODO :: ADD BACK !!
                      dispatch(connect()).then(() => {
                        getData();
                        getAccessToken();
                      });
                      //TODO :: ADD BACK !!


                    }} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                    Connect Wallet <ArrowForward />
                  </Button>

                ) : (

                  <>
                    <Button disabled onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                      Coming soon...
                    </Button>
                  </>
                )
                }


              </HeroBtnWrapper>
            </div>
          }





        </HeroContent>
      </HeroContainer>
    </>
  );






}

export default HeroSection;

