import styled from 'styled-components';
import { Link } from 'react-scroll';

export const Button = styled.button`
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 450px;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    font-size: ${({ fontBig }) => (fontBig ? '26px' : '26px')};
    border: white 2px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({ primary }) => (primary ? '#fff' : '#FCA311')};
        color: black;
    }
`

export const ButtonView = styled.button`
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 80px;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    /* font-size: 30px; */
    border: white 1px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-bottom:5px;
    &:hover {
        background: ${({ primary }) => (primary ? '#fff' : '#551A66')};
        color: white;
        border: black 1px solid
    }
`

export const ButtonViewKnight = styled.button`
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 80px;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    /* font-size: 30px; */
    border: white 2px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-bottom:5px;

    &:hover {
        background: ${({ primary }) => (primary ? '#fff' : '#FCA311')};
        color: black;
        border: black 1px solid

    }
`



export const Mint = styled.button`
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 300px;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    font-size: ${({ fontBig }) => (fontBig ? '26px' : '26px')};
    border: white 2px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({ primary }) => (primary ? '#fff' : '#FCA311')};
        color: black;
    }
`

export const QTY = styled.button`
    //border-radius: 50px;
    background: black;
    white-space: nowrap;
    width: 75px;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    font-size: ${({ fontBig }) => (fontBig ? '26px' : '26px')};
    border: white 2px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({ primary }) => (primary ? '#fff' : '#FCA311')};
        color: black;
    }
`


export const Search = styled.input`
    //border-radius: 50px;
    background: #FCA311;
    white-space: nowrap;
    color: black;

    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: black;
    font-size: ${({ fontBig }) => (fontBig ? '26px' : '26px')};
    border: black 2px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${({ primary }) => (primary ? '#fff' : '#FCA311')};
        color: black;
    }

    @media screen and (max-width: 560px) 
    {
        max-width: 300px;
    }
`



export const SearchWizard = styled.input`
    //border-radius: 50px;
    background: #551A66;
    
    white-space: nowrap;

    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: white;
    font-size: ${({ fontBig }) => (fontBig ? '26px' : '26px')};
    border: black 2px solid;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;



    @media screen and (max-width: 560px) 
    {
        max-width: 300px;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: silver;
    opacity: 1; /* Firefox */
    }
`


